import axiosClient from "../axiosClient";

export interface RelatedInteraction {
  RelatedInteractionID: number;
  ResultedIn: number;
  ResultedFrom: number;
  FromID: number;
  FromTitle: string;
  FromProjectID: number;
  ToProjectID: number;
  ToID: number;
  ToTitle: string;
}

export interface RelatedInteractionsResult {
  relatedInteractions: RelatedInteraction[];
}

export async function getRelatedInteractions(
  accessToken: String
): Promise<RelatedInteractionsResult> {
  const url = `/relatedInteraction`;

  try {
    const { data } = await axiosClient.get<RelatedInteractionsResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function getRelatedInteractionsByInteraction(
  accessToken: String,
  interactionID: number
): Promise<RelatedInteractionsResult> {
  const url = `/relatedInteraction/${interactionID}`;

  try {
    const { data } = await axiosClient.get<RelatedInteractionsResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function createRelatedInteraction(
  accessToken: String,
  newCommInv: Partial<RelatedInteraction>
): Promise<RelatedInteraction> {
  const url = `/relatedInteraction`;

  try {
    const { data } = await axiosClient.post<RelatedInteraction>(
      url,
      newCommInv,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function updateRelatedInteraction(
  accessToken: String,
  commInvID: number,
  newCommInv: Partial<RelatedInteraction>
): Promise<RelatedInteraction> {
  const url = `/relatedInteraction/` + commInvID;

  try {
    const { data } = await axiosClient.post<RelatedInteraction>(
      url,
      newCommInv,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function deleteRelatedInteraction(
  accessToken: String,
  commInvID: number
): Promise<string> {
  const url = `/relatedInteraction/` + commInvID;

  try {
    const { data } = await axiosClient.delete<string>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}
