import { IppDebug } from "app/App";
import axiosClient from "../axiosClient";

export interface CommitmentAction {
  CommitmentActionID: number;
  CommitmentID: number;
  ActionDescription: string;
  ActionDetails: string;
  PersonResponsible: number;
  ActionStatusID: number;
  RequiredDate: Date;
  CompletionDate: Date;
  ActionTypeID: number;
  CommitmentTitle: string;
  ActionTypeName: string;
  ActionStatusName: string;
  ProjectName: string;
  PersonResponsibleName: string;
  OverallResponsibility: string;
  ProjectID: number;
  IsRecurring: boolean;
  ContactID: number | null;
}

export interface CommitmentActionsResult {
  commitmentActions: CommitmentAction[];
}

export async function getCommitmentActions(
  accessToken: String
): Promise<CommitmentActionsResult> {
  const url = `/commitmentAction`;

  try {
    const { data } = await axiosClient.get<CommitmentActionsResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function getCommitmentActionsByCommitment(
  accessToken: String,
  commitmentID: number
): Promise<CommitmentActionsResult> {
  const url = `/commitmentAction/${commitmentID}`;

  try {
    const { data } = await axiosClient.get<CommitmentActionsResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function createCommitmentAction(
  accessToken: String,
  newCommInv: Partial<CommitmentAction>
): Promise<CommitmentAction> {
  const url = `/commitmentAction`;
  IppDebug("API", newCommInv);
  try {
    const { data } = await axiosClient.post<CommitmentAction>(url, newCommInv, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function updateCommitmentAction(
  accessToken: String,
  commInvID: number,
  newCommInv: Partial<CommitmentAction>
): Promise<CommitmentAction> {
  const url = `/commitmentAction/` + commInvID;

  try {
    const { data } = await axiosClient.post<CommitmentAction>(url, newCommInv, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function deleteCommitmentAction(
  accessToken: String,
  commInvID: number
): Promise<string> {
  const url = `/commitmentAction/` + commInvID;

  try {
    const { data } = await axiosClient.delete<string>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}
