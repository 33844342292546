import { GetTokenSilentlyOptions } from "@auth0/auth0-react";
import { downloadTemplate } from "api/crossModule/templateAPI";
import {
  ChildError,
  ChildErrorKey,
  ConstructedErrorItem,
  ConstructedErrors,
  ErrorType,
  GenericErrorTitlesObject,
  ProcessedErrorWithChildList,
  ProcessedErrorWithList,
  ProcessedUploadErrors,
  ReducedErrorList,
  isChildError,
  isChildErrorKey,
} from "./types/index.types";
import React from "react";
import i18next from "i18next";
import { Trans } from "react-i18next";
import { formatStringForCompare } from "./functions";

/**
 * Initializes error structures with default values for tracking upload errors.
 * @returns {ConstructedErrors} An object with keys for each error type, each initialized to default state.
 */
export const getGenericConstructedErrors: () => ConstructedErrors = () => ({
  requiredColumn: {
    count: 0,
    list: [],
    renderAsList: true,
  },
  requiredData: {
    count: 0,
    childList: [],
  },
  incorrectType: {
    count: 0,
    childList: [],
  },
  characterLimit: {
    count: 0,
    childList: [],
  },
});

/**
 * Initializes warning structures with default values for tracking potential issues in uploads that are not critical.
 * @returns {ConstructedErrors} An object with keys for each warning type, each initialized to default state.
 */
export const getGenericConstructedWarnings: () => ConstructedErrors = () => ({
  optionalColumn: {
    count: 0,
    list: [],
    renderAsList: true,
  },
  disabledFeature: {
    count: 0,
    get message() {
      return this.count > 0 ? (
        <Trans
          i18nKey="strGen:uploaderrors.warnings.generic.disabledfeature.message"
          count={this.count}
        />
      ) : (
        ""
      );
    },
    childList: [],
  },
  invalidHours: {
    count: 0,
    childList: [],
  },
});
//----------------------------------------//

/**
 * Generates human-readable titles for errors based on their type and quantity.
 * @param {GenericErrorTitlesObject} titleGenerator - A mapping function to generate titles based on error types.
 * @returns {string} A formatted title for the error, suitable for display.
 */
export const generateErrorTitle: GenericErrorTitlesObject = {
  requiredColumn: (count: number, singleItem?: string) =>
    count > 0 ? (
      <Trans
        i18nKey="strGen:uploaderrors.errors.generic.requiredcolumn.title"
        count={count}
        values={{ variable: singleItem }}
      />
    ) : (
      ""
    ),
  requiredData: (count: number) =>
    count > 0 ? (
      <Trans
        i18nKey="strGen:uploaderrors.errors.generic.requiredata.title"
        count={count}
      />
    ) : (
      ""
    ),
  incorrectType: (count: number) =>
    count > 0 ? (
      <Trans
        i18nKey="strGen:uploaderrors.errors.generic.incorrecttype.title"
        count={count}
      />
    ) : (
      ""
    ),
  characterLimit: (count: number) =>
    count > 0 ? (
      <Trans
        i18nKey="strGen:uploaderrors.errors.generic.characterlimit.title"
        count={count}
      />
    ) : (
      ""
    ),
  optionalColumn: (count: number, singleItem?: string) =>
    count > 0 ? (
      <Trans
        i18nKey="strGen:uploaderrors.warnings.generic.optionalcolumn.title"
        count={count}
        values={{ variable: singleItem }}
      />
    ) : (
      ""
    ),
  disabledFeature: (count: number) =>
    count > 0 ? (
      <Trans
        i18nKey="strGen:uploaderrors.warnings.generic.disabledfeature.title"
        count={count}
      />
    ) : (
      ""
    ),
  invalidHours: (count: number) =>
    count > 0 ? (
      <Trans
        i18nKey="strGen:uploaderrors.warnings.generic.invalidHours.title"
        count={count}
      />
    ) : (
      ""
    ),
};

// Mapping of ChildErrorKeys to specific ErrorTypes, determining the nature of the validation message.
const errorMessageMapping: Record<ChildErrorKey, ErrorType> = {
  requiredData: "headerOnly",
  incorrectType: "mustBe",
  characterLimit: "characterLimit",
  disabledFeature: "headerOnly",
  newCompanies: "headerOnly",
  invalidRegion: "headerOnly",
};

// Configuration object that provides functions to generate error messages based on error type.
const errorMessageConfig: Record<
  ErrorType,
  (
    headerTitle: string,
    expectedValue?: string | number,
    count?: number
  ) => React.ReactNode
> = {
  headerOnly: (headerTitle: string) => (
    <>
      <strong>{headerTitle}</strong>
    </>
  ),
  mustBe: (headerTitle: string, expectedValue?: string | number) => (
    <Trans
      i18nKey="strGen:uploaderrors.errors.generic.message.mustbe"
      components={[<strong />]}
      values={{ headertitle: headerTitle, expectedvalue: expectedValue }}
    />
  ),
  characterLimit: (headerTitle: string, expectedValue?: string | number) => (
    <Trans
      i18nKey="strGen:uploaderrors.errors.generic.message.characterlimit"
      components={[<strong />]}
      values={{ headertitle: headerTitle, expectedvalue: expectedValue }}
    />
  ),
};

// Generates error messages based on the error key, header, and expected value.
const generateErrorMessage = (
  header: string,
  key: ChildErrorKey,
  expectedValue?: string | number,
  messageOverride?: React.ReactNode
): React.ReactNode => {
  if (messageOverride) return messageOverride;

  // Retrieve the error type based on the key
  const errorType = errorMessageMapping[key];
  // Retrieve the error message function based on the error type
  const errorMessage = errorMessageConfig[errorType];
  return errorMessage(header, expectedValue);
};

/**
 * Groups a list of child errors into a structured object by headers, facilitating aggregated error messages.
 * @param {ChildError[]} list - An array of child error objects.
 * @param {keyof typeof errorMessageMapping} key - The error type key to use for message generation.
 * @returns {ReducedErrorList} A structured object with headers as keys and detailed error information as values.
 */

export const reduceErrorList = (
  list: ChildError[],
  key: keyof typeof errorMessageMapping
): ReducedErrorList => {
  return list.reduce<ReducedErrorList>((reducedList, curr) => {
    const rowKey = i18next.t("strGen:uploaderrors.rownumber", {
      rownumber: curr.rowNumber,
    });
    // Check if the header already exists in the reducedList
    if (reducedList[curr.header]) {
      reducedList[curr.header].list.push(rowKey);
    } else {
      // If the header does not exist, create a new entry
      reducedList[curr.header] = {
        title: generateErrorMessage(
          curr.header,
          key,
          curr.expectedValue,
          curr.messageOverride
        ),
        list: [rowKey],
      };
    }
    return reducedList;
  }, {});
};

const getSingleListItem = (item: ConstructedErrorItem): string | undefined => {
  return item.list && item.list.length === 1 ? item.list[0] : undefined;
};

/**
 * Processes constructed error objects to create a structured list of errors formatted for display.
 * @param {Record<string, ConstructedErrorItem>} constructedObject - The object containing structured error data.
 * @param {GenericErrorTitlesObject} titleGenerator - A function to generate titles for each error based on its metadata.
 * @returns {ProcessedUploadErrors[]} An array of processed errors, each formatted for user display. CAn be returned as a list or a child list, which will be used to determine how it displays in the UI.
 */

export const processConstructedErrorsObject = (
  constructedObject: {
    [key: string]: ConstructedErrorItem;
  },
  titleGenerator: GenericErrorTitlesObject
): ProcessedUploadErrors[] => {
  return Object.keys(constructedObject)
    .filter((key) => constructedObject[key].count !== 0)
    .map((key) => {
      const item = constructedObject[key];
      const title = titleGenerator[key](item.count, getSingleListItem(item));
      const base = {
        title,
        message: item.message,
        renderAsList: item.renderAsList,
        showSingleItem: item.showSingleItem,
      };

      if (item.list) {
        return {
          ...base,
          list: item.list,
        } as ProcessedErrorWithList;
      }

      if (item.childList && isChildErrorKey(key)) {
        return {
          ...base,
          childList: reduceErrorList(item.childList, key),
        } as ProcessedErrorWithChildList;
      }

      // If neither 'list' nor 'childList' is applicable, return a default structure with an empty list
      return {
        ...base,
        list: [],
      } as ProcessedErrorWithList;
    });
};

/**
 * Adds an error or warning to the relevant list in an error tracking object, optionally avoiding duplication.
 * @param {ConstructedErrorItem} errorObject - The error object to modify.
 * @param {ChildError | string} listItem - The error item to add, which could be a structured `ChildError` or a simple string.
 * @param {boolean} [onlyCountUniqueHeaders=false] - Whether to count only unique headers for this type of error. This is useful for specific types of errors where it is important to distinguish between the amount of types of header errors versus the total amount of errors.
 */

export const pushListError = (
  errorObject: ConstructedErrorItem,
  listItem: ChildError | string,
  onlyCountUniqueHeaders?: boolean
): void => {
  let shouldIncrementCount = true;
  // Check if the listItem is a ChildError using a type guard
  if (isChildError(listItem)) {
    // Ensure the childList is initialized
    errorObject.childList ||= [];

    if (onlyCountUniqueHeaders) {
      // Check if an error with the same header already exists
      const headerExists = errorObject.childList.some(
        (child) => child.header === listItem.header
      );

      // Increment count only if the header does not exist
      shouldIncrementCount = !headerExists;
    }

    errorObject.childList.push(listItem);
  } else {
    // Ensure the list is initialized
    errorObject.list ||= [];
    errorObject.list.push(listItem);
  }

  // Increment the count conditionally
  if (shouldIncrementCount) {
    errorObject.count++;
  }
};

interface FeatureToggleConfig {
  toggle: boolean | undefined;
  header: string;
  messageOverride?: React.ReactNode; // can be used to override the default error message
}

/**
 * Processes feature toggle configurations and generates errors for any disabled features based on spreadsheet data.
 * @param {FeatureToggleConfig[]} featureConfigs - Configuration for each feature toggle including header and optional message override.
 * @param {any} row - The current row from the spreadsheet being processed.
 * @param {number} rowNum - The row number for error reporting purposes.
 * @param {ConstructedErrorItem} errorObject - The error object where errors will be pushed.
 */
export const pushFeatureToggles = (
  featureConfigs: FeatureToggleConfig[],
  row: any,
  rowNum: number,
  errorObject: ConstructedErrorItem
): void => {
  featureConfigs.forEach(({ toggle, header, messageOverride }) => {
    if (!toggle) {
      const value = row[header]?.toString() ?? "";
      if (value.trim() !== "") {
        pushListError(
          errorObject,
          {
            header: header,
            rowNumber: rowNum,
            ...(messageOverride ? { messageOverride: messageOverride } : {}),
          },
          true // onlyCountUniqueHeaders
        );
      }
    }
  });
};

export const isBooleanFalse = (response: string): boolean => {
  const trimmedResponse = response.toLowerCase().trim();
  return (
    trimmedResponse === "" ||
    trimmedResponse.startsWith("n") ||
    trimmedResponse.startsWith("f")
  );
};

export const generateTemplate = (
  getAccessTokenSilently: (
    options?: GetTokenSilentlyOptions | undefined
  ) => Promise<string>,
  templateType: string,
  id?: number
) => {
  (async () => {
    try {
      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
        },
      });

      downloadTemplate(templateType, accessToken, id);
    } catch (e) {
      console.error(e);
    }
  })();
};

export const resolveBool = (rawValue: string | number | undefined): boolean => {
  const normalizedValue = formatStringForCompare(rawValue);
  return normalizedValue
    ? !normalizedValue.startsWith("f") && !normalizedValue.startsWith("n")
    : false;
};
