// used from the InteractionForm page, to show related communications
// along with the ability to add or remove linked items

import { useDispatch, useSelector } from "react-redux";
import { AddLink, RemoveCircle } from "@mui/icons-material";
import {
  Grid,
  Tooltip,
  Typography,
  Dialog,
  DialogContent,
  IconButton,
  ButtonGroup,
} from "@mui/material";
import {
  Grid as KendoGrid,
  GridColumn,
  GridPageChangeEvent,
} from "@progress/kendo-react-grid";
import { ChangeEvent, useState } from "react";
import { openSnackBar } from "features/snackBar/SnackBarSlice";
import { IppAutocomplete } from "components/IppAutocomplete";
import { useAuth0 } from "@auth0/auth0-react";
import {
  addRelatedInteraction,
  delRelatedInteraction,
} from "./relatedInteractionSlice";
import { CustomLinkCell } from "../../../components/Inventory/customCells";
import { getCurrentModule } from "utils/urlUtils";
import { useTypedTranslation } from "utils/customHooks";
import { RootState } from "app/rootReducer";
import { IppSaveButton } from "components/Buttons/IppSaveButton";
import { IppCancelButton } from "components/Buttons/IppCancelButton";
import { IppDeleteButton } from "components/Buttons/IppDeleteButton";
import { IppAddButton } from "components/Buttons/IppAddButton";
import { Trans } from "react-i18next";

interface pageProps {
  tableData: any;
  options: any;
  interactionID: number;
  parentData: any;
  updateFunction: any;
  showButtons: boolean;
}

export const RelatedInteractionInventory = (props: pageProps) => {
  const {
    tableData,
    options,
    interactionID,
    parentData,
    updateFunction,
    showButtons,
  } = props;
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const t = useTypedTranslation(["objPlt", "strGen", "objCom", "objStk"]);

  const [skip, setSkip] = useState(0);
  const [take, setTake] = useState(10);
  const [editOpen, setEditOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [activeID, setActiveID] = useState(-1);
  const [relatedID, setRelatedID] = useState(-1);
  const [activeLabel, setActiveLabel] = useState("");
  const actionOptions = [
    t("objStk:objects.interaction.relatedinteraction.options.resultedin"),
    t("objStk:objects.interaction.relatedinteraction.options.resultedfrom"),
  ];
  const [actionValue, setActionValue] = useState("");

  const { currentProfile, currentUserRoleList, currentUserRolesById } =
    useSelector((state: RootState) => state.profile);

  const pageChange = (event: GridPageChangeEvent) => {
    setSkip(event.page.skip);
    setTake(event.page.take);
  };

  const handleEditOpen = (props: any) => {
    setActiveID(props.dataItem.RelatedInteractionID);
    setEditOpen(true);
  };

  const handleAddOpen = () => {
    setEditOpen(true);
  };

  const handleEditClose = (event: any, reason: any) => {
    if (reason === "escapeKeyDown" || reason === "backdropClick") {
      dispatch(
        openSnackBar(
          t("strGen:prompts.pleaseselecteither", {
            fieldname: t("strGen:buttons.save"),
            fieldname2: t("strGen:buttons.cancel"),
          }),
          "info"
        )
      );
    } else {
      // form was closed using either Save or Cancel
      setEditOpen(!editOpen);
    }
  };

  const handleDeleteOpen = (props: any) => {
    setActiveID(props.dataItem.RelatedInteractionID);
    setDeleteOpen(true);
  };

  const handleDeleteClose = (event: any, reason: any) => {
    if (reason === "escapeKeyDown" || reason === "backdropClick") {
      dispatch(
        openSnackBar(
          t("strGen:prompts.pleaseselecteither", {
            fieldname: t("strGen:buttons.delete"),
            fieldname2: t("strGen:buttons.cancel"),
          }),
          "info"
        )
      );
    } else {
      // form was closed using either Save or Cancel
      setEditOpen(!editOpen);
    }
  };

  const handleCancelClicked = () => {
    setRelatedID(-1);
    setEditOpen(false);
    setDeleteOpen(false);
  };

  //sorted items to be displayed in drop down
  let sortedOptions = options.sort((a: any, b: any) => {
    return b.InteractionID - a.InteractionID;
  });

  const handleSaveClicked = async () => {
    try {
      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
        },
      });
      // build appropriate expression based on action
      let newItem =
        actionValue ===
        t("objStk:objects.interaction.relatedinteraction.options.resultedin")
          ? { ResultedIn: relatedID, ResultedFrom: interactionID }
          : { ResultedIn: interactionID, ResultedFrom: relatedID };
      dispatch(addRelatedInteraction(accessToken, newItem, interactionID));

      updateFunction(true);
      setEditOpen(false);
      setRelatedID(-1);
      setActionValue("");
    } catch (e) {
      console.error(e);
    }
  };

  const handleDeleteClicked = () => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        dispatch(delRelatedInteraction(accessToken, activeID));
        setDeleteOpen(false);
      } catch (e) {
        console.error(e);
      }
    })();
  };

  const ActionCell = (props: any) => {
    // set canWRITE to true for Client Admins
    let canWRITE = true;

    // if user has access to parent Interaction
    if (showButtons) {
      // if User is not an Admin, check for UserRoles
      if (!currentProfile.IsClientAdmin) {
        const userRoles = currentUserRoleList.map(
          (id) => currentUserRolesById[id]
        );

        if (
          props.dataItem.ActionType ===
          t("objStk:objects.interaction.relatedinteraction.options.resultedin")
        ) {
          // Must have Stakeholder PowerUser Role on the Project for the Interaction that the current Interaction Resulted In.
          canWRITE = userRoles.some(
            (role) =>
              role.ProjectID === props.dataItem.ToProjectID &&
              role.ModuleRoleID === 5
          );
        }
        if (
          props.dataItem.ActionType ===
          t(
            "objStk:objects.interaction.relatedinteraction.options.resultedfrom"
          )
        ) {
          // Must have Stakeholder PowerUser Role on the Project for the Interaction that the current Interaction Resulted From.
          canWRITE = userRoles.some(
            (role) =>
              role.ProjectID === props.dataItem.FromProjectID &&
              role.ModuleRoleID === 5
          );
        }
      }
    } else {
      // if user does not have access to the parent Interaction
      canWRITE = false;
    }

    // Hides Options Buttons completely if User is View Only
    if (canWRITE) {
      return (
        <td>
          <ButtonGroup variant="text" size="small">
            <Tooltip
              title={t("strGen:buttons.tooltip.removelinkobj", {
                objectname: t(
                  "objStk:objects.interaction.relatedinteraction.name"
                ),
              })}
            >
              <IconButton
                size="small"
                onClick={() => handleDeleteOpen(props)}
                disabled={!canWRITE}
              >
                <RemoveCircle
                  fontSize="inherit"
                  color={canWRITE ? "error" : "disabled"}
                />
              </IconButton>
            </Tooltip>
          </ButtonGroup>
        </td>
      );
    } else {
      return null;
    }
  };

  let editForm = (
    <form>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="h6">
            {t("strGen:buttons.addobj", {
              objectname: t(
                "objStk:objects.interaction.relatedinteraction.name"
              ),
            })}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">
            {"C-" +
              parentData.CommunicationID +
              "  " +
              parentData.InteractionTitle}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <IppAutocomplete
            id="ActionItem"
            options={actionOptions}
            value={actionValue}
            onChangeFunction={(event: ChangeEvent, newValue: any) => {
              if (newValue) {
                setActionValue(newValue);
              } else {
                setActionValue("");
              }
            }}
            label={t(
              "objStk:objects.interaction.relatedinteraction.fields.natureofrelation"
            )}
            isEditing={true}
            setIsEditing={null}
            optionLabelFunction={(option: any) => option}
            errorFunction={null}
            touchedFunction={null}
            textValueFunction={actionValue}
          />
        </Grid>
        <Grid item xs={12}>
          <IppAutocomplete
            id="TargetID"
            options={sortedOptions}
            value={options.find((obj: any) => {
              return obj.InteractionID === activeID;
            })}
            onChangeFunction={(event: ChangeEvent, newValue: any) => {
              if (newValue) {
                setRelatedID(newValue.InteractionID);
                setActiveLabel(
                  "C-" +
                    newValue.CommunicationID +
                    " " +
                    newValue.InteractionTitle
                );
              } else {
                setRelatedID(-1);
              }
            }}
            label={t(
              "objStk:objects.interaction.relatedinteraction.fields.relateditem"
            )}
            isEditing={true}
            setIsEditing={null}
            optionLabelFunction={(option: any) =>
              "C-" + option.CommunicationID + " " + option.InteractionTitle
            }
            errorFunction={null}
            touchedFunction={null}
            textValueFunction={activeLabel}
          />
        </Grid>
        <Grid item xs={12}>
          <IppSaveButton
            handleSave={handleSaveClicked}
            disabled={actionValue === "" || relatedID === -1}
          />
          <IppCancelButton onClick={handleCancelClicked} />
        </Grid>
      </Grid>
    </form>
  );

  let deleteForm = (
    <form>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="h6">
            {t("strGen:prompts.unlink.title", {
              fieldname: t(
                "objStk:objects.interaction.relatedinteraction.name"
              ),
            })}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">
            <Trans
              i18nKey="strGen:prompts.unlink.removelink"
              components={[<strong />]}
              values={{
                variable: `C-${parentData.CommunicationID} ${parentData.InteractionTitle}`,
              }}
            />
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <IppDeleteButton onClick={handleDeleteClicked} />
          <IppCancelButton onClick={handleCancelClicked} />
        </Grid>
      </Grid>
    </form>
  );

  let returnGrid = (
    <div>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={8}>
              <Typography variant="h6">
                {t("objStk:objects.interaction.relatedinteraction.name")}
              </Typography>
            </Grid>
            {showButtons && (
              <Grid item xs={4}>
                <Grid container justifyContent="flex-end">
                  <IppAddButton
                    buttonText={t("strGen:buttons.linkitem")}
                    tooltip={t("strGen:buttons.tooltip.linkitem", {
                      objectname: t("objStk:objects.interaction.name"),
                    })}
                    onClick={handleAddOpen}
                    startIcon={<AddLink />}
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <KendoGrid
            data={tableData.slice(skip, (take ? take : 10) + (skip || 0))}
            pageable={{
              buttonCount: 5,
              info: true,
              // type: "input",
              pageSizes: [5, 10, 25, 50, 100],
            }}
            skip={skip}
            take={take}
            total={tableData.length}
            onPageChange={pageChange}
            sortable={true}
          >
            <GridColumn
              field="ActionType"
              title={t(
                "objStk:objects.interaction.relatedinteraction.fields.natureofrelation"
              )}
              width={160}
            />
            <GridColumn
              field="LinkDetails"
              title={t(
                "objStk:objects.interaction.relatedinteraction.fields.relateditem"
              )}
              cell={(props) => (
                <CustomLinkCell
                  {...props}
                  path={
                    props.dataItem["ActionType"] ===
                    t(
                      "objStk:objects.interaction.relatedinteraction.options.resultedfrom"
                    )
                      ? `/${getCurrentModule()}/communications/${
                          props.dataItem["ResultedFrom"]
                        }`
                      : `/${getCurrentModule()}/communications/${
                          props.dataItem["ResultedIn"]
                        }`
                  }
                  key={"Related item"}
                />
              )}
            />
            <GridColumn cell={ActionCell} width={100} />
          </KendoGrid>
        </Grid>
      </Grid>

      <Dialog open={editOpen} onClose={handleEditClose}>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <div>{editForm}</div>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>

      <Dialog open={deleteOpen} onClose={handleDeleteClose}>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <div>{deleteForm}</div>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );

  return <>{returnGrid}</>;
};
