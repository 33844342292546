import { Tooltip } from "@mui/material";
import {
  EmailOutlined,
  MeetingRoomOutlined,
  ComputerOutlined,
  GroupOutlined,
  PhoneOutlined,
  SentimentDissatisfiedTwoTone,
  SentimentNeutralTwoTone,
  SentimentSatisfiedTwoTone,
  SentimentVeryDissatisfiedTwoTone,
  SentimentVerySatisfiedTwoTone,
  Star,
  PhoneIphoneOutlined,
  EventOutlined,
  MarkunreadMailboxOutlined,
  Public,
  RadioOutlined,
  HomeOutlined,
  DragHandle,
  KeyboardArrowDown,
  KeyboardArrowUp,
  LanguageOutlined,
  ConnectWithoutContactOutlined,
  Diversity3Outlined,
  PersonPin,
  RemoveCircle,
  AddCircle,
} from "@mui/icons-material";
import { useTypedTranslation } from "utils/customHooks";

export const GetIcons = (field: string, type: string) => {
  switch (field) {
    case "InteractionTypeName":
      return GetInteractionTypeIcons(type);
    case "SentimentLevel":
      return GetSentimentIcons(type);
    case "IsPrimary":
      return GetIsPrimaryIcons(type);
    case "PriorityName":
      return GetPriorityIcons(type);
    case "AddressedTo":
      return GetAddressedToIcons(type);
    case "Action":
      return GetActionIcons(type);
    default:
      return null;
  }
};

function GetIsPrimaryIcons(type: string) {
  const t = useTypedTranslation(["strGen"]);
  switch (type as unknown as boolean) {
    case true:
      return (
        <Tooltip
          title={t(
            "strGen:components.inventoryicons.getisprimaryicons.tooltip"
          )}
          placement="right"
        >
          <Star fontSize="small" />
        </Tooltip>
      );
    default:
      return "";
  }
}

function GetInteractionTypeIcons(type: string) {
  const t = useTypedTranslation(["strGen"]);
  switch (type) {
    case "Email":
      return (
        <Tooltip
          title={t(
            "strGen:components.inventoryicons.getinteractiontypeicons.email"
          )}
          placement="right"
        >
          <EmailOutlined fontSize="small" />
        </Tooltip>
      );
    case "Phone Call":
      return (
        <Tooltip
          title={t(
            "strGen:components.inventoryicons.getinteractiontypeicons.phonecall"
          )}
          placement="right"
        >
          <PhoneOutlined fontSize="small" />
        </Tooltip>
      );
    case "Meeting (in person)":
      return (
        <Tooltip
          title={t(
            "strGen:components.inventoryicons.getinteractiontypeicons.mettinginperson"
          )}
          placement="right"
        >
          <GroupOutlined fontSize="small" />
        </Tooltip>
      );
    case "Meeting (virtual)":
      return (
        <Tooltip
          title={t(
            "strGen:components.inventoryicons.getinteractiontypeicons.meetingvirtual"
          )}
          placement="right"
        >
          <ComputerOutlined fontSize="small" />
        </Tooltip>
      );
    case "Regular Mail":
      return (
        <Tooltip
          title={t(
            "strGen:components.inventoryicons.getinteractiontypeicons.regularmail"
          )}
          placement="right"
        >
          <EmailOutlined fontSize="small" />
        </Tooltip>
      );
    case "Event":
      return (
        <Tooltip
          title={t(
            "strGen:components.inventoryicons.getinteractiontypeicons.event"
          )}
          placement="right"
        >
          <EventOutlined fontSize="small" />
        </Tooltip>
      );
    case "Text Message":
      return (
        <Tooltip
          title={t(
            "strGen:components.inventoryicons.getinteractiontypeicons.textmessage"
          )}
          placement="right"
        >
          <PhoneIphoneOutlined fontSize="small" />
        </Tooltip>
      );
    case "Registered Mail":
      return (
        <Tooltip
          title={t(
            "strGen:components.inventoryicons.getinteractiontypeicons.registeredmail"
          )}
          placement="right"
        >
          <MarkunreadMailboxOutlined fontSize="small" />
        </Tooltip>
      );
    case "Location Based Mail-Out":
      return (
        <Tooltip
          title={t(
            "strGen:components.inventoryicons.getinteractiontypeicons.locationbasedmailout"
          )}
          placement="right"
        >
          <Public fontSize="small" />
        </Tooltip>
      );
    case "Media":
      return (
        <Tooltip
          title={t(
            "strGen:components.inventoryicons.getinteractiontypeicons.media"
          )}
          placement="right"
        >
          <RadioOutlined fontSize="small" />
        </Tooltip>
      );
    case "Open House":
      return (
        <Tooltip
          title={t(
            "strGen:components.inventoryicons.getinteractiontypeicons.openhouse"
          )}
          placement="right"
        >
          <HomeOutlined fontSize="small" />
        </Tooltip>
      );
    case "Web Platform":
      return (
        <Tooltip
          title={t(
            "strGen:components.inventoryicons.getinteractiontypeicons.webplatform"
          )}
          placement="right"
        >
          <LanguageOutlined fontSize="small" />
        </Tooltip>
      );
    case "Social Media":
      return (
        <Tooltip
          title={t(
            "strGen:components.inventoryicons.getinteractiontypeicons.socialmedia"
          )}
          placement="right"
        >
          <ConnectWithoutContactOutlined fontSize="small" />
        </Tooltip>
      );
    case "Focus Group":
      return (
        <Tooltip
          title={t(
            "strGen:components.inventoryicons.getinteractiontypeicons.focusgroup"
          )}
          placement="right"
        >
          <Diversity3Outlined fontSize="small" />
        </Tooltip>
      );
    case "Cultural Event":
      return (
        <Tooltip
          title={t(
            "strGen:components.inventoryicons.getinteractiontypeicons.culturalevent"
          )}
          placement="right"
        >
          <EventOutlined fontSize="small" />
        </Tooltip>
      );
    case "Informal Encounter":
      return (
        <Tooltip
          title={t(
            "strGen:components.inventoryicons.getinteractiontypeicons.informalencounter"
          )}
          placement="right"
        >
          <GroupOutlined fontSize="small" />
        </Tooltip>
      );
    default:
      return (
        <Tooltip
          title={t(
            "strGen:components.inventoryicons.getinteractiontypeicons.other"
          )}
          placement="right"
        >
          <MeetingRoomOutlined fontSize="small" />
        </Tooltip>
      );
  }
}

function GetSentimentIcons(type: string) {
  const t = useTypedTranslation(["strGen"]);

  type = type ? type.toString() : "";

  switch (type) {
    case "1":
      return (
        <Tooltip
          title={t(
            "strGen:components.inventoryicons.getsentimenticons.verydissatisfied"
          )}
          placement="right"
        >
          <SentimentVeryDissatisfiedTwoTone color="error" />
        </Tooltip>
      );
    case "2":
      return (
        <Tooltip
          title={t(
            "strGen:components.inventoryicons.getsentimenticons.dissatisfied"
          )}
          placement="right"
        >
          <SentimentDissatisfiedTwoTone color="error" />
        </Tooltip>
      );
    case "3":
      return (
        <Tooltip
          title={t(
            "strGen:components.inventoryicons.getsentimenticons.neutral"
          )}
          placement="right"
        >
          <SentimentNeutralTwoTone color="warning" />
        </Tooltip>
      );
    case "4":
      return (
        <Tooltip
          title={t(
            "strGen:components.inventoryicons.getsentimenticons.satisfied"
          )}
          placement="right"
        >
          <SentimentSatisfiedTwoTone color="info" />
        </Tooltip>
      );
    case "5":
      return (
        <Tooltip
          title={t(
            "strGen:components.inventoryicons.getsentimenticons.verysatisfied"
          )}
          placement="right"
        >
          <SentimentVerySatisfiedTwoTone color="success" />
        </Tooltip>
      );
    default:
      return "";
  }
}

function GetPriorityIcons(type: string) {
  const t = useTypedTranslation(["strGen"]);

  type = type ? type.toString() : "";

  switch (type) {
    case "High":
      return (
        <Tooltip
          title={t("strGen:components.inventoryicons.getpriorityicons.high")}
          placement="right"
        >
          <KeyboardArrowUp
            fontSize="small"
            color="error"
            sx={{ paddingTop: 0.75, paddingRight: 0.5 }}
          />
        </Tooltip>
      );
    case "Medium":
      return (
        <Tooltip
          title={t("strGen:components.inventoryicons.getpriorityicons.medium")}
          placement="right"
        >
          <DragHandle
            fontSize="small"
            color="warning"
            sx={{ paddingTop: 0.75, paddingRight: 0.5 }}
          />
        </Tooltip>
      );
    case "Low":
      return (
        <Tooltip
          title={t("strGen:components.inventoryicons.getpriorityicons.low")}
          placement="right"
        >
          <KeyboardArrowDown
            fontSize="small"
            color="info"
            sx={{ paddingTop: 0.75, paddingRight: 0.5 }}
          />
        </Tooltip>
      );
    default:
      return "";
  }
}

function GetAddressedToIcons(type: string) {
  const t = useTypedTranslation(["strGen"]);

  switch (type as unknown as boolean) {
    case true:
      return (
        <Tooltip
          title={t(
            "strGen:components.inventoryicons.getaddressedtoicons.addressedto"
          )}
          placement="right"
        >
          <PersonPin fontSize="small" />
        </Tooltip>
      );
    default:
      return "";
  }
}

function GetActionIcons(type: string) {
  switch (type) {
    case "add":
      return (
        <Tooltip title={"Click to add"} placement="right">
          <AddCircle
            fontSize="small"
            color="error"
            sx={{ paddingTop: 0.75, paddingRight: 0.5 }}
          />
        </Tooltip>
      );
    case "remove":
      return (
        <Tooltip title={"Click to remove"} placement="right">
          <RemoveCircle
            fontSize="inherit"
            color="error"
            sx={{ paddingTop: 0.75, paddingRight: 0.5 }}
          />
        </Tooltip>
      );
    default:
      return "";
  }
}
