import axiosClient from "../axiosClient";

export interface ProximityIndicator {
  ProximityIndicatorID: number;
  ProximityIndicatorName: string;
}

export interface ProximityIndicatorsResult {
  proximityIndicators: ProximityIndicator[];
}

export async function getProximityIndicators(
  accessToken: String
): Promise<ProximityIndicatorsResult> {
  const url = `/proximityIndicators`;

  try {
    const { data } = await axiosClient.get<ProximityIndicatorsResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return data;
  } catch (err: any) {
    throw err;
  }
}
