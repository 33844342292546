import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  RelatedInteraction,
  RelatedInteractionsResult,
  getRelatedInteractions,
  getRelatedInteractionsByInteraction,
  createRelatedInteraction,
  updateRelatedInteraction,
  deleteRelatedInteraction,
} from "api/stakeholder/relatedInteractionAPI";
import { AppThunk } from "app/store";
import * as Constants from "utils/snackBarConstants";
import { openSnackBar } from "features/snackBar/SnackBarSlice";

interface RelatedInteractionState {
  relatedInteractionsById: Record<number, RelatedInteraction>;
  relatedInteractionList: number[];
  isLoading: boolean;
  subRelatedInteractionisLoading: boolean;
  error: string | null;
}

const RelatedInteractionInitialState: RelatedInteractionState = {
  relatedInteractionsById: {},
  relatedInteractionList: [],
  isLoading: false,
  subRelatedInteractionisLoading: false,
  error: null,
};

function startLoading(state: RelatedInteractionState) {
  state.isLoading = true;
}

function loadingFailed(
  state: RelatedInteractionState,
  action: PayloadAction<string>
) {
  state.isLoading = false;
  state.error = action.payload;
}

const relatedInteractions = createSlice({
  name: "relatedInteractions",
  initialState: RelatedInteractionInitialState,
  reducers: {
    getRelatedInteractionsStart: startLoading,
    getRelatedInteractionsSuccess(
      state,
      { payload }: PayloadAction<RelatedInteractionsResult>
    ) {
      const { relatedInteractions } = payload;

      state.isLoading = false;
      state.error = null;

      relatedInteractions.forEach((relatedInteraction) => {
        state.relatedInteractionsById[relatedInteraction.RelatedInteractionID] =
          relatedInteraction;
      });

      state.relatedInteractionList = relatedInteractions.map(
        (relatedInteraction) => relatedInteraction.RelatedInteractionID
      );
    },
    getRelatedInteractionsFailure: loadingFailed,
    createRelatedInteractionStart: startLoading,
    createRelatedInteractionSuccess(
      state,
      { payload }: PayloadAction<RelatedInteraction>
    ) {
      const { RelatedInteractionID } = payload;
      state.relatedInteractionsById[RelatedInteractionID] = payload;
      state.relatedInteractionList.push(RelatedInteractionID);

      state.isLoading = false;
      state.error = null;
    },
    updateRelatedInteractionSuccess(
      state,
      { payload }: PayloadAction<RelatedInteraction>
    ) {
      const { RelatedInteractionID } = payload;

      state.relatedInteractionsById[RelatedInteractionID] = payload;
      //state.relatedInteractionList.push(RelatedInteractionID);

      state.isLoading = false;
      state.error = null;
    },
    deleteRelatedInteractionSuccess(state, { payload }: PayloadAction<number>) {
      const RelatedInteractionID = payload;
      delete state.relatedInteractionsById[RelatedInteractionID];
      state.relatedInteractionList = state.relatedInteractionList.filter(
        (item) => item !== RelatedInteractionID
      );

      state.isLoading = false;
      state.error = null;
    },
    createRelatedInteractionFailure: loadingFailed,
  },
});

export const {
  getRelatedInteractionsStart,
  getRelatedInteractionsSuccess,
  getRelatedInteractionsFailure,
  createRelatedInteractionStart,
  createRelatedInteractionSuccess,
  updateRelatedInteractionSuccess,
  deleteRelatedInteractionSuccess,
  createRelatedInteractionFailure,
} = relatedInteractions.actions;

export default relatedInteractions.reducer;

export const fetchRelatedInteractions =
  (accessToken: String): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getRelatedInteractionsStart());
      const relatedInteractions = await getRelatedInteractions(accessToken);
      dispatch(getRelatedInteractionsSuccess(relatedInteractions));
    } catch (err: any) {
      dispatch(getRelatedInteractionsFailure(err.toString()));
    }
  };

export const fetchRelatedInteractionsByInteraction =
  (accessToken: String, interactionID: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getRelatedInteractionsStart());
      const relatedInteractions = await getRelatedInteractionsByInteraction(
        accessToken,
        interactionID
      );
      dispatch(getRelatedInteractionsSuccess(relatedInteractions));
    } catch (err: any) {
      dispatch(getRelatedInteractionsFailure(err.toString()));
    }
  };

export const addRelatedInteraction =
  (
    accessToken: String,
    newRelatedInteraction: Partial<RelatedInteraction>,
    interactionID: number
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createRelatedInteractionStart());
      const relatedInteraction = await createRelatedInteraction(
        accessToken,
        newRelatedInteraction
      );
      dispatch(createRelatedInteractionSuccess(relatedInteraction));
      dispatch(
        fetchRelatedInteractionsByInteraction(accessToken, interactionID)
      );
      dispatch(openSnackBar(Constants.ADD_SUCCESS, "success"));
    } catch (err: any) {
      dispatch(createRelatedInteractionFailure(err.toString()));
      dispatch(openSnackBar(Constants.FAILED, "error"));
    }
  };

export const updRelatedInteraction =
  (
    accessToken: String,
    relatedInteractionID: number,
    newRelatedInteraction: Partial<RelatedInteraction>
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createRelatedInteractionStart());
      const relatedInteraction = await updateRelatedInteraction(
        accessToken,
        relatedInteractionID,
        newRelatedInteraction
      );

      dispatch(updateRelatedInteractionSuccess(relatedInteraction));
      dispatch(openSnackBar(Constants.UPDATE_SUCCESS, "success"));
    } catch (err: any) {
      dispatch(createRelatedInteractionFailure(err.toString()));
      dispatch(openSnackBar(Constants.FAILED, "error"));
    }
  };

export const delRelatedInteraction =
  (accessToken: String, relatedInteractionID: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(createRelatedInteractionStart());
      const result = await deleteRelatedInteraction(
        accessToken,
        relatedInteractionID
      );
      dispatch(deleteRelatedInteractionSuccess(relatedInteractionID));
      dispatch(openSnackBar(Constants.DELETE_SUCCESS, "success"));
    } catch (err: any) {
      dispatch(createRelatedInteractionFailure(err.toString()));
      dispatch(openSnackBar(Constants.FAILED, "error"));
    }
  };
