import { combineReducers } from "@reduxjs/toolkit";

import clientReducer from "features/client/ClientSlice";
import usersReducer from "features/users/UsersSlice";
import companiesReducer from "features/benefits/companies/CompaniesSlice";
import companyShortFormsReducer from "features/benefits/companies/CompanyShortFormSlice";
import profileReducer from "features/profile/ProfileSlice";
import currentCompanyReducer from "features/benefits/companies/CurrentCompanySlice";
import projectsReducer from "features/project/ProjectSlice";
import projectComponentsReducer from "features/project/ProjectComponentSlice";
import moduleRolesReducer from "features/roles/ModuleRoleSlice";
import userRolesReducer from "features/roles/UserRoleSlice";
import workflowStageReducer from "features/datalists/WorkflowStageSlice";
import paymentsReducer from "features/benefits/payments/PaymentSlice";
import paymentPeriodReducer from "features/benefits/paymentPeriods/PaymentPeriodSlice";
import paymentPeriodStatusReducer from "features/benefits/paymentPeriods/PaymentPeriodStatusSlice";
import employeesReducer from "features/benefits/employees/EmployeeSlice";
import jobHistoryReducer from "features/benefits/jobHistory/JobHistorySlice";
import workHourPeriodReducer from "features/benefits/workHours/WorkHourPeriodSlice";
import workHourDetailReducer from "features/benefits/workHours/WorkHourDetailSlice";
import snackBarReducer from "features/snackBar/SnackBarSlice";
import communityInvestmentTypeReducer from "features/datalists/CommunityInvestmentTypeSlice";
import communityInvestmentReducer from "features/benefits/communityInvestments/CommunityInvestmentSlice";
import notificationTypesReducer from "features/datalists/NotificationTypeSlice";
import notificationsReducer from "features/notifications/notificationSlice";
import projectContactsReducer from "features/notifications/projectContactSlice";
import companyProjectsReducer from "features/notifications/companyProjectSlice";
import notificationTemplatesReducer from "features/platform/admin/notificationTemplates/NotificationTemplateSlice";
import attachmentsReducer from "features/platform/AttachmentSlice";
import deleteCheckReducer from "features/deleteCheck/DeleteCheckSlice";
import workHourChartReducer from "features/benefits/charts/WorkHourChartSlice";
import expenditureChartReducer from "features/benefits/charts/ExpenditureChartSlice";
import affiliationHoursChartReducer from "features/benefits/charts/AffiliationHoursChartSlice";
import employeesByGenderChartReducer from "features/benefits/charts/EmployeesByGenderChartSlice";
import benefitsTilesReducer from "features/benefits/charts/BenefitsTilesSlice";
import vendorActiveEmployeesReducer from "features/benefits/vendorInsights/VendorActiveEmployees";
import vendorSocioEconEmployeesReducer from "features/benefits/vendorInsights/VendorSocioEconEmployeesSlice";
import vendorSocioEconSpendReducer from "features/benefits/vendorInsights/VendorSocioEconSpendSlice";
import vendorThirdPartySpendReducer from "features/benefits/vendorInsights/VendorThirdPartySpendSlice";
import workHourPeriodExceptionReducer from "features/platform/admin/charts/WorkHourPeriodExceptionSlice";
import paymentPeriodExceptionReducer from "features/platform/admin/charts/PaymentPeriodExceptionSlice";
import schedulerReducer from "features/platform/admin/schedulers/SchedulerSlice";
import actionReminderReducer from "features/platform/admin/actionReminders/ActionReminderSlice";
import scheduledNotificationTypeReducer from "features/datalists/ScheduledNotificationTypeSlice";
import reportEmbedReducer from "features/reporting/ReportEmbedSlice";
import filterStateReducer from "utils/filterSlice";
import feedbacksReducer from "features/feedback/FeedbackSlice";
import stakeholderGroupReducer from "features/platform/groups/StakeholderGroupSlice";
import contactReducer from "features/platform/contacts/ContactSlice";
import contractsReducer from "features/contract/ContractSlice";
import phaseReducer from "features/platform/admin/phase/PhaseSlice";
import tagReducer from "features/platform/admin/tag/TagSlice";
import standardStatementReducer from "features/platform/admin/standardStatement/StandardStatementSlice";
import tierReducer from "features/benefits/tier/TierSlice";
import regionReducer from "features/benefits/regions/RegionSlice";
import projectTypesReducer from "features/project/projectTypes/ProjectTypesSlice";
import initiativeReducer from "features/benefits/initiatives/InitiativeSlice";
import entExpensesReducer from "features/benefits/entExpenses/EnTExpenseSlice";
import initiativeOwnersReducer from "features/benefits/initiatives/InitiativeOwnersSlice";
import RnDExpensesReducer from "features/benefits/RnDExpense/RnDExpenseSlice";
import AssetsReducer from "features/benefits/assets/AssetSlice";
import AssetPercentagesReducer from "features/benefits/assets/AssetPercentageSlice";
import EnTExclusionListReducer from "features/benefits/entExclusionList/EnTExclusionListSlice";
import customRnDReducer from "features/benefits/customRnD/CustomRnDSlice";
import ssheReducer from "features/sshe/SsheSlice";
import educationTrainingReducer from "features/benefits/educationTrainings/EducationTrainingSlice";
import ethnicityReducer from "features/platform/admin/ethnicity/EthnicitySlice";
import customEmpCategoryReducer from "features/platform/admin/customEmpCategory/CustomEmpCategorySlice";

// --------- Data List types Start ------------
import genderReducer from "features/datalists/GenderSlice";
import priorityReducer from "features/datalists/PrioritySlice";
import jobPositionReducer from "features/datalists/JobPositionSlice";
import customJobPositionReducer from "features/datalists/CustomJobPositionSlice";
import companyStructureReducer from "features/datalists/CompanyStructureSlice";
import categoriesReducer from "features/datalists/CategorySlice";
import indigenousGroupsReducer from "features/datalists/IndigenousGroupSlice";
import levelOfImportanceReducer from "features/datalists/LevelOfImportanceSlice";
import actionStatusReducer from "features/datalists/ActionStatusSlice";
import proximityIndicatorReducer from "features/datalists/ProximityIndicatorSlice";
import proximityIndicatorLSDReducer from "features/datalists/PILSDSlice";
import countriesReducer from "features/datalists/CountrySlice";
import countriesStatesReducer from "features/datalists/CountriesStatesSlice";
import statusReducer from "features/datalists/StatusSlice";
import communityInvestmentGroupReducer from "features/datalists/CommunityInvestmentGroupSlice";
// --------- Data List Types End --------------

// --------- Client list start -----------------
import clientGroupsReducer from "features/platform/admin/clientGroups/ClientGroupSlice";
import clientOrganizationTypesReducer from "features/platform/admin/clientOrganizationType/ClientOrganizationTypeSlice";
import clientPositionsReducer from "features/platform/admin/clientPositions/ClientPositionSlice";
import clientCurrenciesReducer from "features/platform/admin/currency/ClientCurrencySlice";
import clientModulesReducer from "features/roles/ClientModuleSlice";
import clientCountriesReducer from "features/platform/admin/clientCountry/ClientCountrySlice";
import clientInteractionTypesReducer from "features/platform/admin/clientInteractionTypes/ClientInteractionTypeSlice";
import clientCommitmentSourceTypesReducer from "features/platform/admin/clientCommitmentSourceTypes/ClientCommitmentSourceTypeSlice";
import clientCommitmentStatusTypesReducer from "features/platform/admin/clientCommitmentStatusType/ClientCommitmentStatusTypeSlice";
import clientCommitmentCategoriesReducer from "features/platform/admin/clientCommitmentCategories/ClientCommitmentCategorySlice";
import clientActionTypesReducer from "features/platform/admin/clientActionTypes/ClientActionTypeSlice";
import clientActivityLocationsReducer from "features/platform/admin/clientActivityLocations/clientActivityLocationSlice";
import clientCompanyStructuresReducer from "features/platform/admin/clientCompanyStructures/ClientCompanyStructureSlice";
// --------- Client list end -----------------

//start--------------------- Stakeholder -----------------------
import organizationTypeReducer from "features/datalists/OrganizationTypeSlice";
import interactionTypeReducer from "features/datalists/InteractionTypeSlice";
import statusTypeReducer from "features/datalists/StatusTypeSlice";
import interactionReducer from "features/stakeholder/interaction/InteractionSlice";
import interactionCommentReducer from "features/stakeholder/interactionComment/InteractionCommentSlice";
import issueCommentReducer from "features/stakeholder/interactionComment/IssueCommentSlice";
import interactionActionReducer from "features/stakeholder/interactionAction/InteractionActionSlice";
import relatedInteractionReducer from "features/stakeholder/interaction/relatedInteractionSlice";
import threadInteractionsReducer from "features/stakeholder/thread/ThreadInteractionSlice";
import threadReducer from "features/stakeholder/thread/ThreadSlice";
import interactionPhaseReducer from "features/stakeholder/interactionPhase/InteractionPhaseSlice";
import issueReducer from "features/stakeholder/issue/IssueSlice";
import contactIssueReducer from "features/stakeholder/contactIssue/ContactIssueSlice";
import contactGroupReducer from "features/stakeholder/contactGroup/ContactGroupSlice";
import groupIssueReducer from "features/stakeholder/groupIssue/GroupIssueSlice";
import groupInteractionReducer from "features/stakeholder/groupInteraction/GroupInteractionSlice";
import contactInteractionReducer from "features/stakeholder/contactInteraction/ContactInteractionSlice";
import interactionIssueReducer from "features/stakeholder/interactionIssue/InteractionIssueSlice";
import grievanceReducer from "features/stakeholder/grievance/GrievanceSlice";
import grievanceContactReducer from "features/stakeholder/grievanceContact/GrievanceContactSlice";
import grievanceGroupReducer from "features/stakeholder/grievanceGroup/GrievancGroupSlice";
import grievanceInteractionReducer from "features/stakeholder/grievanceInteraction/GrievanceInteractionSlice";
import grievanceIssueReducer from "features/stakeholder/grievanceIssue/GrievanceIssueSlice";
import grievanceStatusReducer from "features/datalists/GrievanceStatusSlice";
import engagementTileReducer from "features/stakeholder/homePage/EngagementTileSlice";
import engagementLevelReducer from "features/stakeholder/homePage/EngagementLevelSlice";
import engagementHealthReducer from "features/stakeholder/homePage/EngagementHealthSlice";
import stakeholderLogReducer from "features/stakeholder/logTables/StakeholderLogSlice";
import engagementLogReducer from "features/stakeholder/logTables/EngagementLogSlice";
import followupLogReducer from "features/stakeholder/logTables/FollowupLogSlice";
import feedbackByGroupReducer from "features/stakeholder/logTables/FeedbackByGroupSlice";
import feedbackByStakeholderReducer from "features/stakeholder/logTables/FeedbackByStakeholderSlice";
import feedbackByIssueReducer from "features/stakeholder/logTables/FeedbackByIssueSlice";
//end--------------------- Stakeholder -----------------------

//start--------------------- Commitments -----------------------
import actionTypeReducer from "features/commitments/actionType/ActionTypeSlice";
import commitmentReducer from "features/commitments/commitment/CommitmentSlice";
import commitmentActionReducer from "features/commitments/commitmentAction/CommitmentActionSlice";
import commitmentCategoryReducer from "features/commitments/commitmentCategory/CommitmentCategorySlice";
import commitmentSourceReducer from "features/commitments/commitmentSource/CommitmentSourceSlice";

import commitmentSourceTypeReducer from "features/datalists/CommitmentSourceTypeSlice";
import commitmentSourceAmendmentReducer from "features/commitments/commitmentSourceAmendment/CommitmentSourceAmendmentSlice";
import commitmentStatusTypeReducer from "features/commitments/commitmentStatusType/CommitmentStatusTypeSlice";
import commitmentActionSummaryReducer from "features/commitments/homePage/CommitmentActionSummarySlice";
import commitmentGroupSummaryReducer from "features/commitments/homePage/CommitmentGroupSummarySlice";
import commitmentPendingActionsReducer from "features/commitments/homePage/CommitmentPendingActionSlice";
import commitmentSummaryReducer from "features/commitments/homePage/CommitmentSummarySlice";
import commitmentPhaseReducer from "features/commitments/commitmentPhase/CommitmentPhaseSlice";
//end--------------------- Commitments -----------------------

//start------------------------Supplier-------------------------
import supplierReducer from "features/suppliers/supplier/SupplierSlice";
//end-------------------------Supplier---------------------------

//start--------------------- Cross Module ---------------------
import commitmentInteractionReducer from "features/crossModule/stakeholderCommitment/commitmentInteractionSlice";
import optionListReducer from "features/datalists/OptionListSlice";
import clientActivityLocationSlice from "features/platform/admin/clientActivityLocations/clientActivityLocationSlice";
//end--------------------- Cross Module -----------------------

const rootReducer = (routerReducer: any) =>
  combineReducers({
    client: clientReducer,
    users: usersReducer,
    companies: companiesReducer,
    companyShortForms: companyShortFormsReducer,
    profile: profileReducer,
    currentCompany: currentCompanyReducer,
    categories: categoriesReducer,
    indigenousGroups: indigenousGroupsReducer,
    projects: projectsReducer,
    contracts: contractsReducer,
    projectComponents: projectComponentsReducer,
    clientModules: clientModulesReducer,
    moduleRoles: moduleRolesReducer,
    userRoles: userRolesReducer,
    workflowStages: workflowStageReducer,
    payments: paymentsReducer,
    paymentPeriods: paymentPeriodReducer,
    paymentPeriodStatuses: paymentPeriodStatusReducer,
    employees: employeesReducer,
    jobHistory: jobHistoryReducer,
    workHourPeriods: workHourPeriodReducer,
    workHourDetails: workHourDetailReducer,
    initiatives: initiativeReducer,
    entExpenses: entExpensesReducer,
    entExclusionList: EnTExclusionListReducer,
    initiativeOwners: initiativeOwnersReducer,
    genders: genderReducer,
    priorities: priorityReducer,
    actionStatuss: actionStatusReducer,
    jobPositions: jobPositionReducer,
    customJobPositions: customJobPositionReducer,
    levelOfImportances: levelOfImportanceReducer,
    companyStructures: companyStructureReducer,
    communityInvestmentTypes: communityInvestmentTypeReducer,
    communityInvestments: communityInvestmentReducer,
    notificationTypes: notificationTypesReducer,
    proximityIndicators: proximityIndicatorReducer,
    proximityIndicatorLSDs: proximityIndicatorLSDReducer,
    notifications: notificationsReducer,
    projectContacts: projectContactsReducer,
    companyProjects: companyProjectsReducer,
    snackBar: snackBarReducer,
    notificationTemplates: notificationTemplatesReducer,
    attachments: attachmentsReducer,
    deleteChecks: deleteCheckReducer,
    workHourCharts: workHourChartReducer,
    expenditureCharts: expenditureChartReducer,
    affiliationHourCharts: affiliationHoursChartReducer,
    employeesByGenderCharts: employeesByGenderChartReducer,
    workHourPeriodExceptions: workHourPeriodExceptionReducer,
    paymentPeriodExceptions: paymentPeriodExceptionReducer,
    schedulers: schedulerReducer,
    actionReminders: actionReminderReducer,
    scheduledNotificationTypes: scheduledNotificationTypeReducer,
    reportEmbed: reportEmbedReducer,
    organizationTypes: organizationTypeReducer,
    phase: phaseReducer,
    standardStatement: standardStatementReducer,
    tag: tagReducer,
    tier: tierReducer,
    region: regionReducer,
    projectTypes: projectTypesReducer,
    RnDExpense: RnDExpensesReducer,
    countries: countriesReducer,
    countriesStates: countriesStatesReducer,
    benefitsTiles: benefitsTilesReducer,
    vendorActiveEmployees: vendorActiveEmployeesReducer,
    vendorSocioEconEmployees: vendorSocioEconEmployeesReducer,
    vendorSocioEconSpend: vendorSocioEconSpendReducer,
    vendorThirdPartySpend: vendorThirdPartySpendReducer,
    status: statusReducer,
    communityInvestmentGroups: communityInvestmentGroupReducer,
    assets: AssetsReducer,
    assetPercentages: AssetPercentagesReducer,
    customRnD: customRnDReducer,
    sshe: ssheReducer,
    educationTrainings: educationTrainingReducer,
    ethnicity: ethnicityReducer,
    customEmpCategories: customEmpCategoryReducer,

    // stakeholder
    stakeholderGroups: stakeholderGroupReducer,
    contacts: contactReducer,
    interactionTypes: interactionTypeReducer,
    interactions: interactionReducer,
    interactionPhases: interactionPhaseReducer,
    interactionComments: interactionCommentReducer,
    issueComments: issueCommentReducer,
    interactionActions: interactionActionReducer,
    statusTypes: statusTypeReducer,
    issues: issueReducer,
    relatedInteractions: relatedInteractionReducer,
    threadInteractions: threadInteractionsReducer,
    threads: threadReducer,
    contactGroups: contactGroupReducer,
    contactIssues: contactIssueReducer,
    groupIssues: groupIssueReducer,
    groupInteractions: groupInteractionReducer,
    contactInteractions: contactInteractionReducer,
    interactionIssues: interactionIssueReducer,
    grievances: grievanceReducer,
    grievanceContacts: grievanceContactReducer,
    grievanceGroups: grievanceGroupReducer,
    grievanceInteractions: grievanceInteractionReducer,
    grievanceIssues: grievanceIssueReducer,
    grievanceStatus: grievanceStatusReducer,
    filterState: filterStateReducer,
    feedbacks: feedbacksReducer,
    engagementTiles: engagementTileReducer,
    engagementLevels: engagementLevelReducer,
    engagementHealths: engagementHealthReducer,
    stakeholderLogs: stakeholderLogReducer,
    engagementLogs: engagementLogReducer,
    followupLogs: followupLogReducer,
    feedbackByGroups: feedbackByGroupReducer,
    feedbackByStakeholders: feedbackByStakeholderReducer,
    feedbackByIssues: feedbackByIssueReducer,
    // Client Lists
    clientGroups: clientGroupsReducer,
    clientPositions: clientPositionsReducer,
    clientCurrencies: clientCurrenciesReducer,
    clientInteractionTypes: clientInteractionTypesReducer,
    clientCommitmentStatusTypes: clientCommitmentStatusTypesReducer,
    clientCommitmentSourceTypes: clientCommitmentSourceTypesReducer,
    clientCommitmentCategories: clientCommitmentCategoriesReducer,
    clientCountries: clientCountriesReducer,
    clientOrganizationTypes: clientOrganizationTypesReducer,
    clientActionTypes: clientActionTypesReducer,
    clientActivityLocations: clientActivityLocationsReducer,
    clientCompanyStructures: clientCompanyStructuresReducer,
    // commitments
    actionTypes: actionTypeReducer,
    commitments: commitmentReducer,
    commitmentActions: commitmentActionReducer,
    commitmentCategories: commitmentCategoryReducer,
    commitmentSources: commitmentSourceReducer,

    commitmentSourceTypes: commitmentSourceTypeReducer,
    commitmentSourceAmendments: commitmentSourceAmendmentReducer,
    commitmentStatusTypes: commitmentStatusTypeReducer,
    commitmentActionSummarys: commitmentActionSummaryReducer,
    commitmentGroupSummarys: commitmentGroupSummaryReducer,
    commitmentPendingActions: commitmentPendingActionsReducer,
    commitmentSummarys: commitmentSummaryReducer,
    commitmentPhases: commitmentPhaseReducer,
    // supplier
    suppliers: supplierReducer,
    // cross module
    commitmentInteractions: commitmentInteractionReducer,
    optionLists: optionListReducer,
    // base router; DON'T TOUCH
    router: routerReducer,
  });

export interface RootState {
  client: ReturnType<typeof clientReducer>;
  users: ReturnType<typeof usersReducer>;
  companies: ReturnType<typeof companiesReducer>;
  companyShortForms: ReturnType<typeof companyShortFormsReducer>;
  profile: ReturnType<typeof profileReducer>;
  currentCompany: ReturnType<typeof currentCompanyReducer>;
  categories: ReturnType<typeof categoriesReducer>;
  indigenousGroups: ReturnType<typeof indigenousGroupsReducer>;
  clientGroups: ReturnType<typeof clientGroupsReducer>;
  clientPositions: ReturnType<typeof clientPositionsReducer>;
  clientCommitmentCategories: ReturnType<
    typeof clientCommitmentCategoriesReducer
  >;
  clientCurrencies: ReturnType<typeof clientCurrenciesReducer>;
  clientCountries: ReturnType<typeof clientCountriesReducer>;
  clientInteractionTypes: ReturnType<typeof clientInteractionTypesReducer>;
  clientCommitmentStatusTypes: ReturnType<
    typeof clientCommitmentStatusTypesReducer
  >;
  clientCommitmentSourceTypes: ReturnType<
    typeof clientCommitmentSourceTypesReducer
  >;
  clientOrganizationTypes: ReturnType<typeof clientOrganizationTypesReducer>;
  clientActionTypes: ReturnType<typeof clientActionTypesReducer>;
  clientActivityLocations: ReturnType<typeof clientActivityLocationsReducer>;
  clientCompanyStructures: ReturnType<typeof clientCompanyStructuresReducer>;
  projects: ReturnType<typeof projectsReducer>;
  projectComponents: ReturnType<typeof projectComponentsReducer>;
  contracts: ReturnType<typeof contractsReducer>;
  clientModules: ReturnType<typeof clientModulesReducer>;
  moduleRoles: ReturnType<typeof moduleRolesReducer>;
  userRoles: ReturnType<typeof userRolesReducer>;
  workflowStages: ReturnType<typeof workflowStageReducer>;
  payments: ReturnType<typeof paymentsReducer>;
  initiatives: ReturnType<typeof initiativeReducer>;
  entExpenses: ReturnType<typeof entExpensesReducer>;
  entExclusionList: ReturnType<typeof EnTExclusionListReducer>;
  initiativeOwners: ReturnType<typeof initiativeOwnersReducer>;
  paymentPeriods: ReturnType<typeof paymentPeriodReducer>;
  paymentPeriodStatuses: ReturnType<typeof paymentPeriodStatusReducer>;
  proximityIndicators: ReturnType<typeof proximityIndicatorReducer>;
  proximityIndicatorLSDs: ReturnType<typeof proximityIndicatorLSDReducer>;
  employees: ReturnType<typeof employeesReducer>;
  jobHistory: ReturnType<typeof jobHistoryReducer>;
  workHourPeriods: ReturnType<typeof workHourPeriodReducer>;
  workHourDetails: ReturnType<typeof workHourDetailReducer>;
  genders: ReturnType<typeof genderReducer>;
  priorities: ReturnType<typeof priorityReducer>;
  actionStatuss: ReturnType<typeof actionStatusReducer>;
  jobPositions: ReturnType<typeof jobPositionReducer>;
  customJobPositions: ReturnType<typeof customJobPositionReducer>;
  levelOfImportances: ReturnType<typeof levelOfImportanceReducer>;
  companyStructures: ReturnType<typeof companyStructureReducer>;
  communityInvestments: ReturnType<typeof communityInvestmentReducer>;
  communityInvestmentTypes: ReturnType<typeof communityInvestmentTypeReducer>;
  notificationTypes: ReturnType<typeof notificationTypesReducer>;
  notifications: ReturnType<typeof notificationsReducer>;
  companyProjects: ReturnType<typeof companyProjectsReducer>;
  projectContacts: ReturnType<typeof projectContactsReducer>;
  snackBar: ReturnType<typeof snackBarReducer>;
  notificationTemplates: ReturnType<typeof notificationTemplatesReducer>;
  attachments: ReturnType<typeof attachmentsReducer>;
  deleteChecks: ReturnType<typeof deleteCheckReducer>;
  workHourCharts: ReturnType<typeof workHourChartReducer>;
  expenditureCharts: ReturnType<typeof expenditureChartReducer>;
  benefitsTiles: ReturnType<typeof benefitsTilesReducer>;
  vendorActiveEmployees: ReturnType<typeof vendorActiveEmployeesReducer>;
  vendorSocioEconEmployees: ReturnType<typeof vendorSocioEconEmployeesReducer>;
  vendorSocioEconSpend: ReturnType<typeof vendorSocioEconSpendReducer>;
  vendorThirdPartySpend: ReturnType<typeof vendorThirdPartySpendReducer>;
  affiliationHourCharts: ReturnType<typeof affiliationHoursChartReducer>;
  employeesByGenderCharts: ReturnType<typeof employeesByGenderChartReducer>;
  workHourPeriodExceptions: ReturnType<typeof workHourPeriodExceptionReducer>;
  paymentPeriodExceptions: ReturnType<typeof paymentPeriodExceptionReducer>;
  schedulers: ReturnType<typeof schedulerReducer>;
  actionReminders: ReturnType<typeof actionReminderReducer>;
  scheduledNotificationTypes: ReturnType<
    typeof scheduledNotificationTypeReducer
  >;
  reportEmbed: ReturnType<typeof reportEmbedReducer>;
  organizationTypes: ReturnType<typeof organizationTypeReducer>;
  stakeholderGroups: ReturnType<typeof stakeholderGroupReducer>;
  contacts: ReturnType<typeof contactReducer>;
  phase: ReturnType<typeof phaseReducer>;
  ethnicity: ReturnType<typeof ethnicityReducer>;
  customEmpCategories: ReturnType<typeof customEmpCategoryReducer>;
  standardStatement: ReturnType<typeof standardStatementReducer>;
  tag: ReturnType<typeof tagReducer>;
  tier: ReturnType<typeof tierReducer>;
  region: ReturnType<typeof regionReducer>;
  projectTypes: ReturnType<typeof projectTypesReducer>;
  RnDExpense: ReturnType<typeof RnDExpensesReducer>;
  countries: ReturnType<typeof countriesReducer>;
  countriesStates: ReturnType<typeof countriesStatesReducer>;
  status: ReturnType<typeof statusReducer>;
  communityInvestmentGroups: ReturnType<typeof communityInvestmentGroupReducer>;
  assets: ReturnType<typeof AssetsReducer>;
  assetPercentages: ReturnType<typeof AssetPercentagesReducer>;
  customRnD: ReturnType<typeof customRnDReducer>;
  sshe: ReturnType<typeof ssheReducer>;
  educationTrainings: ReturnType<typeof educationTrainingReducer>;

  //--------------------- Stakeholder -----------------------
  interactions: ReturnType<typeof interactionReducer>;
  interactionActions: ReturnType<typeof interactionActionReducer>;
  interactionTypes: ReturnType<typeof interactionTypeReducer>;
  interactionPhases: ReturnType<typeof interactionPhaseReducer>;
  statusTypes: ReturnType<typeof statusTypeReducer>;
  issues: ReturnType<typeof issueReducer>;
  contactGroups: ReturnType<typeof contactGroupReducer>;
  contactIssues: ReturnType<typeof contactIssueReducer>;
  groupIssues: ReturnType<typeof groupIssueReducer>;
  groupInteractions: ReturnType<typeof groupInteractionReducer>;
  contactInteractions: ReturnType<typeof contactInteractionReducer>;
  relatedInteractions: ReturnType<typeof relatedInteractionReducer>;
  threadInteractions: ReturnType<typeof threadInteractionsReducer>;
  threads: ReturnType<typeof threadReducer>;
  interactionIssues: ReturnType<typeof interactionIssueReducer>;
  grievances: ReturnType<typeof grievanceReducer>;
  grievanceContacts: ReturnType<typeof grievanceContactReducer>;
  grievanceGroups: ReturnType<typeof grievanceGroupReducer>;
  grievanceInteractions: ReturnType<typeof grievanceInteractionReducer>;
  grievanceIssues: ReturnType<typeof grievanceIssueReducer>;
  grievanceStatus: ReturnType<typeof grievanceStatusReducer>;
  actionTypes: ReturnType<typeof actionTypeReducer>;
  engagementTiles: ReturnType<typeof engagementTileReducer>;
  engagementLevels: ReturnType<typeof engagementLevelReducer>;
  engagementHealths: ReturnType<typeof engagementHealthReducer>;
  stakeholderLogs: ReturnType<typeof stakeholderLogReducer>;
  engagementLogs: ReturnType<typeof engagementLogReducer>;
  followupLogs: ReturnType<typeof followupLogReducer>;
  feedbackByGroups: ReturnType<typeof feedbackByGroupReducer>;
  feedbackByStakeholders: ReturnType<typeof feedbackByStakeholderReducer>;
  feedbackByIssues: ReturnType<typeof feedbackByIssueReducer>;
  interactionComments: ReturnType<typeof interactionCommentReducer>;
  issueComments: ReturnType<typeof issueCommentReducer>;
  //--------------------- Stakeholder -----------------------

  //--------------------- Commitments -----------------------
  commitments: ReturnType<typeof commitmentReducer>;
  commitmentActions: ReturnType<typeof commitmentActionReducer>;
  commitmentCategories: ReturnType<typeof commitmentCategoryReducer>;
  commitmentSources: ReturnType<typeof commitmentSourceReducer>;
  commitmentSourceTypes: ReturnType<typeof commitmentSourceTypeReducer>;
  commitmentSourceAmendments: ReturnType<
    typeof commitmentSourceAmendmentReducer
  >;
  commitmentStatusTypes: ReturnType<typeof commitmentStatusTypeReducer>;
  commitmentActionSummarys: ReturnType<typeof commitmentActionSummaryReducer>;
  commitmentGroupSummarys: ReturnType<typeof commitmentGroupSummaryReducer>;
  commitmentPendingActions: ReturnType<typeof commitmentPendingActionsReducer>;
  commitmentSummarys: ReturnType<typeof commitmentSummaryReducer>;
  commitmentPhases: ReturnType<typeof commitmentPhaseReducer>;

  //--------------------- Commitments -----------------------

  //---------------------------Supplier----------------------------------
  suppliers: ReturnType<typeof supplierReducer>;
  //---------------------------Suppliers---------------------------------

  //---------------------Cross Module------------------------
  commitmentInteractions: ReturnType<typeof commitmentInteractionReducer>;
  //---------------------Cross Module------------------------

  filterState: ReturnType<typeof filterStateReducer>;
  optionLists: ReturnType<typeof optionListReducer>;
  feedbacks: ReturnType<typeof feedbacksReducer>;

  router: any;
}

export default rootReducer;
