import axiosClient from "./axiosClient";

export interface Employee {
  EmployeeID: number;
  CompanyID: number;
  CompanyEmployeeID: string;
  GenderID: number;
  GenterText?: string;
  TownCity: string;
  ProvinceState: string;
  CountryName: string;
  IndigenousGroupID: number;
  IndigenousGroupRaw: string;
  IndigenousGroupName?: string;
  Disability: boolean;
  VisibleMinority: boolean;
  Local: boolean;
  JobPositionID: number;
  PositionRaw: string;
  CompanyName?: string;
  JobPositionName?: string;
  LastActive: Date;
  ApprenticeStatus: string;
  ApprenticeYear: number;
  RegionID: number;
  RegionName?: string;
  TempEmployee: boolean;
  ValidFrom: Date;
  DiversityDeclined: boolean;
  LGBTQ: boolean;
  CustomJobPositionID?: number;
  CustomJobPositionRaw?: string;
  EmployeeEthnicity: { EthnicityID: number; EthnicityName: string }[];
  PostalCode: string;
  CustomEmpCategoryID?: number;
  ApprenticeableTrade?: boolean;
}

export interface EmployeesResult {
  employees: Employee[];
}

export type DiversityProfileFormValues = {
  DiversityDeclined: boolean;
  Disability: boolean;
  VisibleMinority: boolean;
  LGBTQ: boolean;
  Local: boolean;
  GenderID: number | null;
  IndigenousGroupID: number | null;
  EmployeeEthnicity: { EthnicityID: number; EthnicityName: string }[];
  CustomEmpCategoryID: number | null;
};

export interface EmployeeNeedsReviewToggles {
  ft_ben_JobPosition: boolean;
  ft_ben_DiversityDeclined: boolean;
  hasRegionTypes: boolean;
}

export async function getEmployees(
  accessToken: String
): Promise<EmployeesResult> {
  const url = `/employee`;

  try {
    const { data } = await axiosClient.get<EmployeesResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function getEmployeesByCompany(
  accessToken: String,
  companyID: number
): Promise<EmployeesResult> {
  const url = `/employee/${companyID}`;

  try {
    const { data } = await axiosClient.get<EmployeesResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function getEmployeesForUploadReview(
  accessToken: String
): Promise<EmployeesResult> {
  const url = `/employee/uploadReview`;

  try {
    const { data } = await axiosClient.get<EmployeesResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function createEmployee(
  accessToken: String,
  newEmployee: Partial<Employee>
): Promise<Employee> {
  const url = `/employee`;

  try {
    const { data } = await axiosClient.post<Employee>(url, newEmployee, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    return data;
  } catch (error: any) {
    throw error;
  }
}

export async function updateEmployee(
  accessToken: String,
  employeeID: number,
  newEmployee: Partial<Employee>
): Promise<Employee> {
  const url = `/employee/` + employeeID;

  try {
    const { data } = await axiosClient.post<Employee>(url, newEmployee, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    return data;
  } catch (error: any) {
    throw error;
  }
}

export async function deleteEmployee(
  accessToken: String,
  employeeID: number
): Promise<string> {
  const url = `/employee/` + employeeID;
  try {
    const { data } = await axiosClient.delete<string>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    return data;
  } catch (error: any) {
    throw error;
  }
}
