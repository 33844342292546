import { useAuth0 } from "@auth0/auth0-react";
import { Grid, Typography } from "@mui/material";
import { User } from "api/usersAPI";
import { RootState } from "app/rootReducer";
import { IppSkeleton } from "components/IppSkeleton";
import { fetchUsers } from "features/users/UsersSlice";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDateFromDateString } from "utils/DateFunctions";
import { useTypedTranslation } from "utils/customHooks";

interface Recordhistory {
  refetch?: boolean; // this should be false when there is already a get user dispatch in the parent file
  createdBy?: number;
  createDate: any;
  lastUpdatedBy?: number;
  lastUpdated: any;
}

export const RecordHistoryDisplay = (props: Recordhistory) => {
  const { refetch, createdBy, createDate, lastUpdatedBy, lastUpdated } = props;
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const t = useTypedTranslation(["objPlt", "strGen"]);

  const { clientId } = useSelector((state: RootState) => state.client);

  const { usersById, isLoading } = useSelector(
    (state: RootState) => state.users
  );

  // Fetch users if refetch is true
  useEffect(() => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        refetch && dispatch(fetchUsers(accessToken, clientId));
      } catch (e) {
        console.error(e);
      }
    })();
  }, [refetch, dispatch, getAccessTokenSilently]);

  // Handle the names for Created By and Last Updated By
  const lastUpdatedByName = lastUpdatedBy
    ? `${usersById[lastUpdatedBy]?.FirstName || "Unknown"} ${
        usersById[lastUpdatedBy]?.Surname || ""
      }`
    : "Unknown";

  const createdByName = createdBy
    ? `${usersById[createdBy]?.FirstName || "Unknown"} ${
        usersById[createdBy]?.Surname || ""
      }`
    : "Unknown";

  if (isLoading) return <IppSkeleton height={23} />;
  // shows no history if no date is present regardless of By
  // shows unknown if By is not valid for any reason
  return (
    <Grid item xs={12}>
      <Grid container direction="column">
        {createDate && (
          <Typography variant="caption" sx={{ color: "text.secondary" }}>
            {t("strGen:forms.created", {
              date: getDateFromDateString(createDate),
              name: createdByName,
            })}
          </Typography>
        )}
        {lastUpdated && (
          <Typography variant="caption" sx={{ color: "text.secondary" }}>
            {t("strGen:forms.lastupdated", {
              date: getDateFromDateString(lastUpdated),
              name: lastUpdatedByName,
            })}
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};
