import axiosClient from "../axiosClient";

export interface Contact {
  ContactID: number;
  ContactName: string;
  Surname: string;
  FirstName: string;
  ContactTitle: string;
  Address: string;
  TownCity: string;
  ProvinceState: string;
  Country: string;
  PostalCode: string;
  PrimaryPhone: string;
  AltPhone: string;
  Email: string;
  ClientID: number;
  LastUpdated: Date;
  ModifiedBy: number;
  UserSurname: string;
  UserFirstName: string;
  LatestInteraction: Date;
  FirstGroupName: string;
  Groups: string;
  LevelOfInfluence: number;
  LevelOfInterest: number;
  RelationshipOwner: number;
  RelationSurname: string;
  RelationFirstName: string;
  Notes: string;
  LegalSubdivision: string;
  ProximityIndicator: string;
  InternalUser: boolean;
  Tags?: string;
  ProximityIndicatorLSDs?: [];
  ContactMethodID?: number;
  InteractionTypeName?: string;
}

export interface ContactsResult {
  contacts: Contact[];
}

export async function getContacts(
  accessToken: String
): Promise<ContactsResult> {
  const url = `/contact`;

  try {
    const { data } = await axiosClient.get<ContactsResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

// COMMENTED OUT FOR FUTURE CLEANUP
// export async function getContactsByGroup(
//   accessToken: String,
//   groupID: number
// ): Promise<ContactsResult> {
//   const url = `/contact/group/${groupID}`;

//   try {
//     const { data } = await axiosClient.get<ContactsResult>(url, {
//       headers: {
//         Authorization: `Bearer ${accessToken}`,
//       },
//     });

//     return data;
//   } catch (err: any) {
//     throw err;
//   }
// }

// COMMENTED OUT FOR FUTURE CLEANUP
// export async function getContactsByInteraction(
//   accessToken: String,
//   interactionID: number
// ): Promise<ContactsResult> {
//   const url = `/contact/interaction/${interactionID}`;

//   try {
//     const { data } = await axiosClient.get<ContactsResult>(url, {
//       headers: {
//         Authorization: `Bearer ${accessToken}`,
//       },
//     });

//     return data;
//   } catch (err: any) {
//     throw err;
//   }
// }

export async function createContact(
  accessToken: String,
  newContact: Partial<Contact>,
  groupList: string[],
  issueList: string[]
): Promise<Contact> {
  const url = `/contact`;
  newContact.InternalUser = false;

  try {
    const { data } = await axiosClient.post<Contact>(
      url,
      {
        itemData: newContact,
        groupList: groupList,
        issueList: issueList,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function updateContact(
  accessToken: String,
  contactID: number,
  newContact: Partial<Contact>,
  groupList: string[] | null,
  issueList: string[] | null
): Promise<Contact> {
  const url = `/contact/` + contactID;

  try {
    const { data } = await axiosClient.post<Contact>(
      url,
      {
        itemData: newContact,
        groupList: groupList,
        issueList: issueList,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function mergeContact(
  accessToken: String,
  contactID: number,
  newContact: Partial<Contact>,
  duplicateID: number
): Promise<Contact> {
  const url = `/contact/merge/` + contactID;

  try {
    const { data } = await axiosClient.post<Contact>(
      url,
      {
        itemData: newContact,
        duplicateID: duplicateID,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function deleteContact(
  accessToken: String,
  contactID: number
): Promise<string> {
  const url = `/contact/` + contactID;

  try {
    const { data } = await axiosClient.delete<string>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}
