import { useFormik } from "formik";
import { styled } from "@mui/material/styles";
import * as yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "app/rootReducer";
import { useAuth0 } from "@auth0/auth0-react";
import {
  addCommitmentAction,
  updCommitmentAction,
  delCommitmentAction,
} from "./CommitmentActionSlice";
import {
  Box,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import { ChangeEvent, useEffect, useMemo, useState } from "react";
import { IppFormHeader } from "components/IppFormHeader";
import { IppFormButtons } from "components/Buttons/IppFormButtons";
import { IppTextField } from "components/IppTextField";
import LoadingIndicator from "components/LoadingIndicator";
import { Prompt } from "react-router";
import {
  ConvertDateOffset,
  ConvertToJSDate,
  GetJSDate,
} from "../../../utils/DateFunctions";
import { IppAutocomplete } from "components/IppAutocomplete";
import { IppDisplayField } from "components/IppDisplayField";
import { IppRichTextEditor } from "components/RichTextEditor/IppRichTextEditor";
import { IppDisplayRichText } from "components/IppDisplayRichText";
import { fetchUsers } from "features/users/UsersSlice";
import { fetchCommitments } from "../commitment/CommitmentSlice";
import { fetchActionTypes } from "../actionType/ActionTypeSlice";
import { fetchClientActionTypes } from "features/platform/admin/clientActionTypes/ClientActionTypeSlice";

import { IppAttachmentInventory } from "components/IppAttachmentInventory";
import {
  TabStrip,
  TabStripSelectEventArguments,
  TabStripTab,
} from "@progress/kendo-react-layout";
import { IppDatePicker } from "components/IppDatePicker";
import { IppCheckbox } from "components/IppCheckbox";
import { HierarchyBanner } from "components/HierarchyBanner";
import ScheduleFrequency from "components/ScheduleFrequency";
import { openSnackBar } from "features/snackBar/SnackBarSlice";
import { push } from "redux-first-history";
import { countAttachments } from "api/attachmentsAPI";
import { fetchActionStatuss } from "features/datalists/ActionStatusSlice";
import { Link } from "react-router-dom";
import { Edit } from "@mui/icons-material";
import { IppPersonResponsible } from "components/IppPersonResponsible";
import CommitmentResponsibilities from "components/CommitmentResponsibility";
import { fetchContacts } from "features/platform/contacts/ContactSlice";
import { IsMedSmall } from "utils/mediaQueries";
import { useRoleChecks, useTypedTranslation } from "utils/customHooks";
import { Contact } from "api/stakeholder/contactAPI";
import { PersonRenderOption } from "utils/renderFunctions";
import { fetchStandardStatements } from "features/platform/admin/standardStatement/StandardStatementSlice";
import { IppFormCompleteButton } from "components/Buttons/IppFormCompleteButton";
import { IppDisplayChip } from "components/IppDisplayChip";
import { getActionStatusChips } from "components/Inventory/inventoryStatusChips";
import { usePermissions } from "utils/permissions/usePermissions";
import {
  CommitmentsActionDeleteResource,
  CommitmentsActionEditResource,
} from "utils/types/index.types";

const PREFIX = "CommitmentActionForm";

const classes = {
  editForm: `${PREFIX}-editForm`,
  boxSpace: `${PREFIX}-boxSpace`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.editForm}`]: {
    maxWidth: 1150,
  },

  [`& .${classes.boxSpace}`]: {
    padding: theme.spacing(1),
  },
}));

export const CommitmentActionForm = (props: any) => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const { isActionAllowed } = usePermissions();
  const t = useTypedTranslation(["objPlt", "strGen", "objCom", "objStk"]);
  const customBP = IsMedSmall();

  const [activeTab, setActiveTab] = useState(0);
  const [selectedFrequency, setSelectedFrequency] = useState("");

  const newDate = new Date(
    new Date().setFullYear(new Date().getFullYear() + 1)
  );
  const [continueUntil, setContinueUntil] = useState(newDate);

  const handleTabSelect = (e: TabStripSelectEventArguments) => {
    setActiveTab(e.selected);
  };

  let itemData = props.commitmentAction || {};

  const [isEditing, setIsEditing] = useState(
    itemData.CommitmentActionID ? false : true
  );
  const [isAdding, setIsAdding] = useState(
    itemData.CommitmentActionID ? false : true
  );

  const {
    commitmentList,
    commitmentsById,
    isLoading: commitmentIsLoading,
  } = useSelector((state: RootState) => state.commitments);

  const commitments = commitmentList.map((id) => commitmentsById[id]);
  let filteredCommitments = commitments;

  const relatedCommitment = commitmentsById[itemData.CommitmentID] || {};

  //------------------Define Permissions------------------
  const editPermissionsResource: CommitmentsActionEditResource = {
    module: "Commitments",
    type: "Action",
    action: "edit",
    projectID: itemData.ProjectID,
    personResponsible: itemData.PersonResponsible,
    parent: relatedCommitment,
  };

  const canEdit = useMemo(
    () => isActionAllowed(editPermissionsResource),
    [editPermissionsResource]
  );

  const deletePermissionsResource: CommitmentsActionDeleteResource = {
    module: "Commitments",
    type: "Action",
    action: "delete",
    projectID: itemData.ProjectID,
    personResponsible: itemData.PersonResponsible,
    parent: relatedCommitment,
  };

  const canDelete = useMemo(
    () => isActionAllowed(deletePermissionsResource),
    [deletePermissionsResource]
  );
  //------------------------------------------------------

  // used to display attachments count
  const [attachCount, setAttachCount] = useState<any>({ Count: 0 });

  const {
    clientId,
    isLoading: clientIsLoading,
    ft_all_ExternalPersonRes,
    ft_com_ShowCommitmentHierarchy,
  } = useSelector((state: RootState) => state.client);

  const { currentProfile } = useSelector((state: RootState) => state.profile);

  const {
    userList,
    usersById,
    isLoading: userIsLoading,
  } = useSelector((state: RootState) => state.users);

  //Filter for active users only
  const allUsers = userList.map((id) => usersById[id]);

  const { hasContributorRoleForProject } = useRoleChecks(
    itemData.ProjectID ?? null
  );

  const isContributor = hasContributorRoleForProject();

  if (isContributor) {
    filteredCommitments = filteredCommitments.filter(
      (commitmentment) =>
        commitmentment.PersonResponsible === currentProfile.UserAccountID
    );
  }

  // A Contributor user can only edit ALL fields if they are the person responsible for the related Commitment
  const canEditAllFields =
    currentProfile.IsClientAdmin ||
    !isContributor ||
    relatedCommitment.PersonResponsible === currentProfile.UserAccountID;

  const {
    actionTypeList,
    actionTypesById,
    isLoading: actionTypeIsLoading,
  } = useSelector((state: RootState) => state.actionTypes);

  const actionTypes = actionTypeList.map((id) => actionTypesById[id]);

  const { clientActionTypesById, clientActionTypeList } = useSelector(
    (state: RootState) => state.clientActionTypes
  );

  const clientActionTypes = clientActionTypeList.map(
    (id) => clientActionTypesById[id]
  );

  const {
    actionStatusList,
    actionStatussById,
    isLoading: actionStatusIsLoading,
  } = useSelector((state: RootState) => state.actionStatuss);

  const actionStatuses = actionStatusList.map((id) => actionStatussById[id]);

  //usestate for Rootstate of attachment
  const { attachmentsById, attachmentList } = useSelector(
    (state: RootState) => state.attachments
  );

  const attachments = attachmentList.map((id) => attachmentsById[id]);

  const { contactList, contactsById } = useSelector(
    (state: RootState) => state.contacts
  );

  const contacts = contactList.map((id) => contactsById[id]);

  const { isLoading: standardStatementIsLoading } = useSelector(
    (state: RootState) => state.standardStatement
  );

  useEffect(() => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        dispatch(fetchCommitments(accessToken));
        dispatch(fetchUsers(accessToken, clientId));
        dispatch(fetchActionTypes(accessToken));
        dispatch(fetchClientActionTypes(accessToken));
        dispatch(fetchActionStatuss(accessToken));
        dispatch(fetchContacts(accessToken));
        dispatch(fetchStandardStatements(accessToken));
        const count = await countAttachments(
          accessToken,
          "CommitmentActions",
          itemData.CommitmentActionID
        );
        setAttachCount(count.attachments ? count.attachments : 0);
      } catch (e) {
        console.error(e);
      }
    })();
  }, [clientId, itemData.CommitmentActionID, dispatch, getAccessTokenSilently]);

  // used for attachment count
  useEffect(() => {
    (async () => {
      try {
        if (attachments.length > 0) {
          const attach = attachments.filter((item) => {
            return (
              item.RecordID === itemData.CommitmentActionID &&
              item.RecordType === "CommitmentActions"
            );
          });
          if (attach && attach.length > 0)
            setAttachCount({ Count: attach.length });
        }
      } catch (e) {
        console.error(e);
      }
    })();
  }, [attachmentList]);

  const onSub = (values: any) => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        if (values.IsRecurring) {
          // check required fields
          if (selectedFrequency === "" || continueUntil === null) {
            dispatch(
              openSnackBar(
                t("objCom:objects.commitmentaction.frequencyenddate"),
                "error"
              )
            );
          } else {
            values.EndDate = continueUntil;
            values.Frequency = selectedFrequency;
            dispatch(addCommitmentAction(accessToken, values, false));
            dispatch(push(`/commitments/commitments/${values.CommitmentID}`));
          }
        } else {
          // add only a single action
          dispatch(addCommitmentAction(accessToken, values, true));
        }
      } catch (e) {
        console.error(e);
      }
    })();
  };

  let submitFunc = onSub;

  if (itemData.CommitmentActionID) {
    //Update case
    submitFunc = (values: any) => {
      (async () => {
        try {
          const accessToken = await getAccessTokenSilently({
            authorizationParams: {
              audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
            },
          });

          dispatch(
            updCommitmentAction(
              accessToken,
              values.CommitmentActionID,
              values,
              true
            )
          );
        } catch (e) {
          console.error(e);
        }
      })();
    };
  } else {
    itemData.ActionDescription = "";
    itemData.ActionDetails = "";
    itemData.ActionTypeID = -1;
    itemData.ActionTypeName = "";
    itemData.CommitmentID = -1;
    itemData.ActionStatusID = -1;
    itemData.ActionStatusName = "";
    itemData.CommitmentTitle = "";
    itemData.CompletionDate = null;
    itemData.RequiredDate = null;
    itemData.PersonResponsible = currentProfile.UserAccountID;
    itemData.PersonResponsibleName =
      currentProfile.FirstName + " " + currentProfile.Surname;
    itemData.IsRecurring = false;
    itemData.OverallResponsibility = "";
    itemData.ContactID = null;
  }

  const handleDelete = () => {
    // function to delete current community investment entry
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        dispatch(delCommitmentAction(accessToken, itemData.CommitmentActionID));
      } catch (e) {
        console.error(e);
      }
    })();
  };

  const handleMarkAsComplete = async () => {
    const today = new Date();

    await formik.setFieldValue("CompletionDate", today);
    await formik.setFieldValue("ActionStatusID", 4);

    await formik.submitForm();
  };

  const validationSchema = yup.object({
    ActionDescription: yup
      .string()
      .required(
        t("strGen:validation.required", {
          fieldname: t("objCom:objects.commitmentaction.name"),
        })
      )
      .max(
        250,
        t("strGen:validation.max", {
          fieldname: t(
            "objCom:objects.commitmentaction.fields.actiondescription"
          ),
          count: 250,
        })
      ),
    CommitmentID: yup
      .number()
      .positive(
        t("strGen:validation.required", {
          fieldname: t("objCom:objects.commitment.name"),
        })
      )
      .required(
        t("strGen:validation.required", {
          fieldname: t("objCom:objects.commitment.name"),
        })
      ),
    ActionTypeID: yup
      .number()
      .positive(
        t("strGen:validation.required", {
          fieldname: t("objCom:objects.commitmentaction.fields.actiontype"),
        })
      )
      .required(
        t("strGen:validation.required", {
          fieldname: t("objCom:objects.commitmentaction.fields.actiontype"),
        })
      ),
    ActionStatusID: yup
      .number()
      .positive(
        t("strGen:validation.required", {
          fieldname: t("objCom:objects.commitment.fields.status"),
        })
      )
      .required(
        t("strGen:validation.required", {
          fieldname: t("objCom:objects.commitment.fields.status"),
        })
      ),
    PersonResponsible: yup
      .number()
      .positive(
        t("strGen:validation.required", {
          fieldname: t("objCom:objects.commitment.fields.personresponsible"),
        })
      )
      .required(
        t("strGen:validation.required", {
          fieldname: t("objCom:objects.commitment.fields.personresponsible"),
        })
      ),
    OverallResponsibility: yup.string().nullable(),
    RequiredDate: yup
      .date()
      .required(
        t("strGen:validation.required", {
          fieldname: t("objPlt:platformwide.fields.date"),
        })
      )
      .typeError(t("strGen:validation.date.improperformat")),
    CompletionDate: yup
      .date()
      .max(
        new Date(),
        t("strGen:validation.date.nofuturedate", {
          fieldname: t("objPlt:platformwide.fields.datecompleted"),
        })
      )
      .nullable()
      .when("ActionStatusID", {
        is: 4,
        then: (validationSchema) =>
          validationSchema.required(
            t("strGen:validation.requiredifclosed", {
              fieldname: t("objPlt:platformwide.fields.datecompleted"),
              fieldname2: t("objCom:objects.commitmentaction.fields.status"),
            })
          ),
      })
      .typeError(t("strGen:validation.date.improperformat")),
    ContactID: yup
      .number()
      .nullable()
      .positive(
        t("strGen:validation.invalid", {
          fieldname: t("objPlt:objects.contact.name"),
        })
      ),
  });

  const formik = useFormik({
    initialValues: itemData,
    validationSchema: validationSchema,
    onSubmit: submitFunc,
  });

  const projectID =
    commitments.find((item) => {
      return item.CommitmentID === formik.values.CommitmentID;
    })?.ProjectID || -1;

  const contact = contacts.find((obj) => {
    return obj.ContactID === formik.values.ContactID;
  });

  const showCompleteButton = ![3, 4, 5].includes(formik.values.ActionStatusID);

  let viewForm = (
    <Box display="flex" justifyContent="center">
      <Grid container className={classes.editForm} spacing={1}>
        <Grid item xs={12}>
          <Paper className={classes.boxSpace}>
            <Grid container spacing={1}>
              <IppFormHeader
                title={t("objCom:objects.commitmentaction.commitmentaction", {
                  actioncount: 1,
                })}
                isEditing={isEditing}
                isAdding={isAdding}
              />
              {ft_com_ShowCommitmentHierarchy && (
                <HierarchyBanner
                  items={[
                    {
                      label: t("objPlt:objects.project.name"),
                      value: formik.values.ProjectName,
                    },
                    {
                      label: t("objCom:objects.commitmentsource.name", {
                        sourcecount: 1,
                      }),
                      value: relatedCommitment.CommitmentSourceName,
                      link: !isContributor
                        ? `/commitments/sources/${relatedCommitment.CommitmentSourceID}`
                        : undefined,
                    },
                  ]}
                />
              )}
              <Grid item xs={8}>
                <IppDisplayField
                  value={formik.values.ActionDescription}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  showEdit={canEdit}
                  showEmphasis={true}
                />
              </Grid>
              {showCompleteButton && !isAdding && (
                <Grid item xs={4}>
                  <IppFormCompleteButton
                    completeFunction={() => handleMarkAsComplete()}
                  />
                </Grid>
              )}
              <Grid item xs={6}>
                <Grid container spacing={0}>
                  <Grid item xs={12}>
                    <Typography
                      variant="caption"
                      sx={{ color: "text.secondary" }}
                    >
                      {t("objCom:objects.commitment.relatedcommitment", {
                        count: 1,
                      })}{" "}
                    </Typography>
                  </Grid>
                  <Grid item xs>
                    <Tooltip
                      title={t(
                        "objCom:objects.commitment.viewrelatedcommitment",
                        { count: 1 }
                      )}
                    >
                      <Link
                        to={`/commitments/commitments/${itemData.CommitmentID}`}
                      >
                        <div style={{ cursor: "pointer", color: "blue" }}>
                          <Typography variant="body2">
                            {formik.values.CommitmentTitle}
                          </Typography>
                        </div>
                      </Link>
                    </Tooltip>
                  </Grid>
                  {canEditAllFields && (
                    <Grid item width={30}>
                      <IconButton
                        onClick={() => setIsEditing(true)}
                        size="small"
                      >
                        <Edit fontSize="inherit" />
                      </IconButton>
                    </Grid>
                  )}
                </Grid>
                <Divider />
              </Grid>
              <Grid item xs={6}>
                <IppDisplayField
                  label={t("objCom:objects.commitmentaction.fields.actiontype")}
                  value={formik.values.ActionTypeName}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  showEdit={canEdit}
                />
              </Grid>
              <Grid item xs={6}>
                <IppDisplayChip
                  label={t("objCom:objects.commitment.fields.status")}
                  value={formik.values.ActionStatusName}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  showEdit={canEdit}
                  statusChip={getActionStatusChips}
                />
              </Grid>
              <Grid item xs={6}>
                <IppDisplayField
                  label={t("objPlt:platformwide.fields.daterequired")}
                  value={ConvertToJSDate(formik.values.RequiredDate)}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  showEdit={canEdit}
                />
              </Grid>
              <Grid item xs={6}>
                <IppDisplayField
                  label={t("objPlt:platformwide.fields.datecompleted")}
                  value={ConvertToJSDate(formik.values.CompletionDate)}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  showEdit={canEdit}
                />
              </Grid>
              <Grid item xs={6}>
                <IppDisplayField
                  label={t(
                    "objCom:objects.commitment.fields.personresponsible"
                  )}
                  value={formik.values.PersonResponsibleName}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  showEdit={canEditAllFields && canEdit}
                />
              </Grid>
              <Grid item xs={6}>
                <IppDisplayField
                  label={t(
                    "objCom:objects.commitment.fields.overallresponsibility"
                  )}
                  value={formik.values.OverallResponsibility}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  showEdit={canEdit}
                />
              </Grid>
              {ft_all_ExternalPersonRes && (
                <Grid item xs={6}>
                  <IppDisplayField
                    label={t(
                      "objCom:objects.commitment.fields.additionalpersonresponsible"
                    )}
                    value={contact?.ContactName}
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                    showEdit={canEdit}
                  />
                </Grid>
              )}
              <Grid item xs={6}>
                {formik.values.IsRecurring && (
                  <Typography
                    variant="caption"
                    sx={{ color: "text.secondary" }}
                  >
                    {t(
                      "objCom:objects.commitmentaction.recurringaction.thisisarecurringaction"
                    )}{" "}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12}>
                <IppDisplayRichText
                  label={t(
                    "objCom:objects.commitmentaction.fields.actiondetails"
                  )}
                  value={formik.values.ActionDetails}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  showEdit={canEdit}
                />
              </Grid>
              <Grid item xs={12}>
                <IppFormButtons
                  isEditing={isEditing}
                  isAdding={isAdding}
                  setIsEditing={setIsEditing}
                  resetFunction={() => formik.resetForm()}
                  showDelete={canDelete}
                  deleteFunction={() => handleDelete()}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );

  let editForm = clientIsLoading ? (
    <LoadingIndicator />
  ) : (
    <form noValidate onSubmit={formik.handleSubmit}>
      {!formik.isSubmitting && (
        <Prompt
          when={formik.dirty}
          message={t("strGen:prompts.unsavedchanges")}
        />
      )}

      <Grid container spacing={1}>
        <IppFormHeader
          title={t("objCom:objects.commitmentaction.commitmentaction", {
            actioncount: 1,
          })}
          isEditing={isEditing}
          isAdding={isAdding}
        />
        <Grid item xs={6}>
          <IppTextField
            id="ActionDescription"
            required={true}
            label={t("objCom:objects.commitmentaction.name")}
            value={formik.values.ActionDescription}
            onChangeFunction={formik.handleChange}
            errorsExpression={formik.errors.ActionDescription}
            touchedExpression={formik.touched.ActionDescription}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
          />
        </Grid>
        <Grid item xs={6}>
          <IppAutocomplete
            id="CommitmentID"
            required={true}
            options={filteredCommitments}
            value={commitments.find((obj) => {
              return obj.CommitmentID === formik.values.CommitmentID;
            })}
            onChangeFunction={(event: ChangeEvent, newValue: any) => {
              if (newValue) {
                formik.setFieldValue("CommitmentID", newValue.CommitmentID);
              } else {
                formik.setFieldValue("CommitmentID", -1);
              }
            }}
            label={t("objCom:objects.commitment.relatedcommitment", {
              count: 1,
            })}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            optionLabelFunction={(option: any) => option.CommitmentTitle}
            errorFunction={formik.errors.CommitmentID}
            touchedFunction={formik.touched.CommitmentID}
            textValueFunction={formik.values.CommitmentID}
            disabled={!canEditAllFields}
          />
        </Grid>
        <Grid item xs={6}>
          <IppAutocomplete
            id="ActionTypeID"
            required={true}
            options={clientActionTypes}
            value={actionTypes.find((obj) => {
              return obj.ActionTypeID === formik.values.ActionTypeID;
            })}
            onChangeFunction={(event: ChangeEvent, newValue: any) => {
              if (newValue) {
                formik.setFieldValue("ActionTypeID", newValue.ActionTypeID);
              } else {
                formik.setFieldValue("ActionTypeID", -1);
              }
            }}
            label={t("objCom:objects.commitmentaction.fields.actiontype")}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            optionLabelFunction={(option: any) => option.ActionTypeName}
            errorFunction={formik.errors.ActionTypeID}
            touchedFunction={formik.touched.ActionTypeID}
            textValueFunction={formik.values.ActionTypeID}
          />
        </Grid>
        <Grid item xs={6}>
          <IppAutocomplete
            id="ActionStatusID"
            required={true}
            options={actionStatuses}
            value={actionStatuses.find((obj) => {
              return obj.ActionStatusID === formik.values.ActionStatusID;
            })}
            onChangeFunction={(event: ChangeEvent, newValue: any) => {
              if (newValue) {
                formik.setFieldValue("ActionStatusID", newValue.ActionStatusID);
              } else {
                formik.setFieldValue("ActionStatusID", -1);
              }
            }}
            label={t("objCom:objects.commitmentaction.fields.status")}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            optionLabelFunction={(option: any) => option.ActionStatusName}
            errorFunction={formik.errors.ActionStatusID}
            touchedFunction={formik.touched.ActionStatusID}
            textValueFunction={formik.values.ActionStatusID}
          />
        </Grid>
        <Grid item xs={6}>
          <IppDatePicker
            id="RequiredDate"
            label={t("objPlt:platformwide.fields.daterequired")}
            required={true}
            value={ConvertDateOffset(formik.values.RequiredDate)}
            onChangeFunction={(newValue: any) => {
              formik.setFieldValue("RequiredDate", GetJSDate(newValue), true);
              formik.setFieldTouched("RequiredDate", true, false);
            }}
            errorsExpression={formik.errors.RequiredDate}
            touchedExpression={formik.touched.RequiredDate}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
          />
        </Grid>
        <Grid item xs={6}>
          <IppDatePicker
            id="CompletionDate"
            label={t("objPlt:platformwide.fields.datecompleted")}
            required={formik.values.ActionStatusID === 4 ? true : false}
            value={ConvertDateOffset(formik.values.CompletionDate)}
            onChangeFunction={(newValue: any) => {
              formik.setFieldValue("CompletionDate", GetJSDate(newValue), true);
              formik.setFieldTouched("CompletionDate", true, false);
            }}
            errorsExpression={formik.errors.CompletionDate}
            touchedExpression={formik.touched.CompletionDate}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
          />
        </Grid>
        <Grid item xs={6}>
          <IppPersonResponsible
            id="PersonResponsible"
            value={allUsers.find((obj) => {
              return obj.UserAccountID === formik.values.PersonResponsible;
            })}
            onChangeFunction={(event: ChangeEvent, newValue: any) => {
              if (newValue) {
                formik.setFieldValue(
                  "PersonResponsible",
                  newValue.UserAccountID
                );
              } else {
                formik.setFieldValue("PersonResponsible", null);
              }
            }}
            label={
              t("objCom:objects.commitment.fields.personresponsible") + "*"
            }
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            optionLabelFunction={(option: any) =>
              option.FirstName + " " + option.Surname
            }
            errorFunction={formik.errors.PersonResponsible}
            touchedFunction={formik.touched.PersonResponsible}
            textValueFunction={formik.values.PersonResponsibleName}
            projectID={projectID}
            disabled={!canEditAllFields}
          />
        </Grid>
        <Grid item xs={6}>
          <IppAutocomplete
            id="OverallResponsibility"
            label={t("objCom:objects.commitment.fields.overallresponsibility")}
            options={CommitmentResponsibilities}
            value={formik.values.OverallResponsibility}
            textValueFunction={formik.values.OverallResponsibility}
            onChangeFunction={(event: ChangeEvent, newValue: any) => {
              if (newValue) {
                formik.setFieldValue("OverallResponsibility", newValue);
              } else {
                formik.setFieldValue("OverallResponsibility", "");
              }
            }}
            touchedFunction={formik.touched.OverallResponsibility}
            errorFunction={formik.errors.OverallResponsibility}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
          />
        </Grid>
        {ft_all_ExternalPersonRes && (
          <Grid item xs={6}>
            <IppAutocomplete
              id="ContactID"
              label={t(
                "objCom:objects.commitment.fields.additionalpersonresponsible"
              )}
              options={contacts}
              renderOption={(props: any, option: Contact) =>
                PersonRenderOption(
                  props,
                  option,
                  [
                    option.Email,
                    option.FirstGroupName,
                    option.PrimaryPhone,
                    option.ContactTitle,
                  ],
                  `* ${t("objPlt:objects.contact.missingname")} *`
                )
              }
              value={contacts.find((obj) => {
                return obj.ContactID === formik.values.ContactID;
              })}
              onChangeFunction={(event: ChangeEvent, newValue: any) => {
                if (newValue) {
                  formik.setFieldValue("ContactID", newValue.ContactID);
                } else {
                  formik.setFieldValue("ContactID", null);
                }
              }}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              optionLabelFunction={(option: any) => option.ContactName}
              errorFunction={formik.errors.ContactID}
              touchedFunction={formik.touched.ContactID}
              textValueFunction={formik.values.ContactName}
              toolTip="An external contact who shares responsibility for this record but does have access to the platform"
            />
          </Grid>
        )}
        {isAdding && (
          <Grid item xs={6}>
            <IppCheckbox
              id="IsRecurring"
              label={t(
                "objCom:objects.commitmentaction.recurringaction.isthisarecurringaction"
              )}
              value={formik.values.IsRecurring}
              onChangeFunction={formik.handleChange}
              isEditing={isEditing}
            />
          </Grid>
        )}
        {formik.values.IsRecurring && isAdding && (
          <>
            <Grid item xs={12}>
              <Typography variant="caption">
                {t(
                  "objCom:objects.commitmentaction.recurringaction.partofarecurringaction"
                )}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <IppAutocomplete
                id="RepeatFrequency"
                options={ScheduleFrequency}
                required={formik.values.IsRecurring}
                value={selectedFrequency}
                onChangeFunction={(event: ChangeEvent, newValue: any) => {
                  if (newValue) {
                    setSelectedFrequency(newValue);
                  } else {
                    setSelectedFrequency("");
                  }
                }}
                textValueFunction={formik.values.RepeatFrequency}
                touchedFunction={formik.touched.RepeatFrequency}
                errorFunction={formik.errors.RepeatFrequency}
                label={t("objCom:objects.commitmentaction.frequency")}
                isEditing={isEditing}
                setIsEditing={setIsEditing}
              />
            </Grid>
            <Grid item xs={6}>
              <IppDatePicker
                id="ContinueUntil"
                label={t("objCom:objects.commitmentaction.continueuntil")}
                value={continueUntil}
                onChangeFunction={(newValue: any) => {
                  setContinueUntil(newValue);
                }}
                // errorsExpression={formik.errors.CompletionDate}
                // touchedExpression={formik.touched.CompletionDate}
                isEditing={isEditing}
                setIsEditing={setIsEditing}
              />
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          <IppRichTextEditor
            id="ActionDetails"
            label={t("objCom:objects.commitmentaction.fields.actiondetails")}
            value={formik.values.ActionDetails}
            isEditing={isEditing}
            touchedExpression={formik.touched.ActionDetails}
            errorsExpression={formik.errors.ActionDetails}
            setFieldValue={formik.setFieldValue}
            standardStatementsRecordType="CommitmentActionDetails"
          />
        </Grid>
        <Grid item xs={12}>
          <IppFormButtons
            isEditing={isEditing}
            isAdding={isAdding}
            setIsEditing={setIsEditing}
            resetFunction={() => formik.resetForm()}
            showDelete={canDelete}
            deleteFunction={() => handleDelete()}
          />
        </Grid>
      </Grid>
    </form>
  );

  let pageForm =
    clientIsLoading &&
    userIsLoading &&
    actionTypeIsLoading &&
    commitmentIsLoading &&
    actionStatusIsLoading &&
    standardStatementIsLoading ? (
      <LoadingIndicator />
    ) : isAdding ? (
      <Box display="flex" justifyContent="center">
        <Grid container className={classes.editForm} spacing={1}>
          <Grid item xs={12}>
            <Paper className={classes.boxSpace}>{editForm}</Paper>
          </Grid>
        </Grid>
      </Box>
    ) : (
      <Grid container spacing={1}>
        <Grid item xl={4} xs={customBP ? 12 : 5}>
          {isEditing ? (
            <Dialog open={isEditing} fullWidth maxWidth="lg">
              <DialogContent>{editForm}</DialogContent>
            </Dialog>
          ) : (
            <div>{viewForm}</div>
          )}
        </Grid>
        <Grid item xl={8} xs={customBP ? 12 : 7}>
          <Box>
            <Paper>
              <TabStrip selected={activeTab} onSelect={handleTabSelect}>
                <TabStripTab
                  title={`${t("objPlt:objects.attachment.name", {
                    count: 2,
                  })} (${attachCount.Count})`}
                >
                  <Box>
                    <Grid className={classes.editForm}>
                      <IppAttachmentInventory
                        recordType="CommitmentActions"
                        itemID={itemData.CommitmentActionID}
                        companyID={formik.values.CompanyID}
                        projectID={0}
                        moduleID={3}
                        canEdit={canEdit}
                      />
                    </Grid>
                  </Box>
                </TabStripTab>
              </TabStrip>
            </Paper>
          </Box>
        </Grid>
      </Grid>
    );

  return <Root>{pageForm}</Root>;
};
