import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  ProximityIndicator,
  ProximityIndicatorsResult,
  getProximityIndicators,
} from "api/stakeholder/proximityIndicatorAPI";
import { AppThunk } from "app/store";

interface ProximityIndicatorState {
  proximityIndicatorsById: Record<number, ProximityIndicator>;
  proximityIndicatorList: number[];
  isLoading: boolean;
  error: string | null;
}

const ProximityIndicatorInitialState: ProximityIndicatorState = {
  proximityIndicatorsById: {},
  proximityIndicatorList: [],
  isLoading: false,
  error: null,
};

function startLoading(state: ProximityIndicatorState) {
  state.isLoading = true;
}

function loadingFailed(
  state: ProximityIndicatorState,
  action: PayloadAction<string>
) {
  state.isLoading = false;
  state.error = action.payload;
}

const proximityIndicators = createSlice({
  name: "proximityIndicator",
  initialState: ProximityIndicatorInitialState,
  reducers: {
    getProximityIndicatorsStart: startLoading,
    getProximityIndicatorsSuccess(
      state,
      { payload }: PayloadAction<ProximityIndicatorsResult>
    ) {
      const { proximityIndicators } = payload;
      state.isLoading = false;
      state.error = null;

      proximityIndicators.forEach((proximityIndicator) => {
        state.proximityIndicatorsById[proximityIndicator.ProximityIndicatorID] =
          proximityIndicator;
      });

      state.proximityIndicatorList = proximityIndicators.map(
        (proximityIndicator) => proximityIndicator.ProximityIndicatorID
      );
    },
    getProximityIndicatorsFailure: loadingFailed,
  },
});

export const {
  getProximityIndicatorsStart,
  getProximityIndicatorsSuccess,
  getProximityIndicatorsFailure,
} = proximityIndicators.actions;

export default proximityIndicators.reducer;

export const fetchProximityIndicators =
  (accessToken: String): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getProximityIndicatorsStart());
      const proximityIndicators = await getProximityIndicators(accessToken);
      dispatch(getProximityIndicatorsSuccess(proximityIndicators));
    } catch (err: any) {
      dispatch(getProximityIndicatorsFailure(err.toString()));
    }
  };
