import { Grid } from "@mui/material";
import IppConfirmDialog from "../IppConfirmDialog";
import { useState } from "react";
import { IppDeleteButton } from "./IppDeleteButton";
import { IppCancelButton } from "./IppCancelButton";
import { IppSubmitButton } from "./IppSubmitButton";
import { useTypedTranslation } from "utils/customHooks";

interface IppFormButtonProps {
  isEditing: boolean;
  setIsEditing: any;
  isAdding: boolean;
  resetFunction?: any;
  showDelete?: boolean;
  showCancel?: boolean;
  deleteFunction?: any;
  info?: any;
  fetchFunc?: any;
  disableAll?: boolean;
  submitDisabled?: boolean;
  cancelDisabled?: boolean;
  deleteDisabled?: boolean;
  recordID?: number;
  approval?: boolean;
  submitTooltip?: string;
  cancelTooltip?: string;
  deleteTooltip?: string;
}

export const IppFormButtons = (props: IppFormButtonProps) => {
  const {
    isEditing,
    setIsEditing,
    isAdding,
    resetFunction,
    showDelete,
    showCancel,
    deleteFunction,
    info,
    fetchFunc,
    disableAll,
    submitDisabled,
    cancelDisabled,
    deleteDisabled,
    recordID,
    approval,
    submitTooltip,
    cancelTooltip,
    deleteTooltip,
  } = props;
  const t = useTypedTranslation(["strGen"]);

  const [confirmOpen, setConfirmOpen] = useState(false);

  const editChange = () => {
    setIsEditing && setIsEditing(!isEditing);

    if (isEditing) {
      resetFunction();
    }
  };

  const handleDelete = () => {
    deleteFunction();
  };

  return (
    <div>
      <Grid container>
        <Grid item xs={8}>
          {isEditing || isAdding ? (
            <div>
              <IppSubmitButton
                buttonText={
                  approval
                    ? t("strGen:buttons.saveapprove")
                    : t("strGen:buttons.savechanges")
                }
                disabled={disableAll || submitDisabled}
                tooltip={submitTooltip}
              />
              {!isAdding || showCancel ? (
                <IppCancelButton
                  onClick={editChange}
                  disabled={disableAll || cancelDisabled}
                  tooltip={cancelTooltip}
                />
              ) : null}
            </div>
          ) : null}
        </Grid>
        <Grid item xs={4}>
          <Grid container justifyContent="flex-end">
            {showDelete && !isAdding ? (
              <IppDeleteButton
                onClick={() => setConfirmOpen(true)}
                disabled={disableAll || deleteDisabled}
                tooltip={deleteTooltip}
              />
            ) : null}
          </Grid>
        </Grid>
      </Grid>
      <IppConfirmDialog
        title={t("strGen:prompts.delete.deleteitem")}
        open={confirmOpen}
        setOpen={setConfirmOpen}
        onConfirm={handleDelete}
        info={info}
        fetchFunc={fetchFunc}
        recordID={recordID}
      />
    </div>
  );
};

IppFormButtons.defaultProps = {
  showDelete: false,
};
