import axiosClient from "api/axiosClient";
import { TagRecordType } from "utils/constants/generalConstants";

export interface Tag {
  TagID: number;
  Name: string;
  RecordType: TagRecordType;
  ClientID: number;
}

export interface TagsResult {
  tags: Tag[];
}

export async function getTags(accessToken: String): Promise<TagsResult> {
  const url = `/tag`;

  try {
    const { data } = await axiosClient.get<TagsResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function getTagsByRecordType(
  accessToken: String,
  recordType: TagRecordType
): Promise<TagsResult> {
  const url = `/tag/recordType/${recordType}`;

  try {
    const { data } = await axiosClient.get<TagsResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function createTag(
  accessToken: String,
  newTag: Partial<Tag>
): Promise<Tag> {
  const url = `/tag`;

  try {
    const { data } = await axiosClient.post<Tag>(url, newTag, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function updateTag(
  accessToken: String,
  tagID: number,
  newTag: Partial<Tag>
): Promise<Tag> {
  const url = `/tag/` + tagID;

  try {
    const { data } = await axiosClient.post<Tag>(url, newTag, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}

export async function deleteTag(
  accessToken: String,
  tagID: number
): Promise<string> {
  const url = `/tag/` + tagID;

  try {
    const { data } = await axiosClient.delete<string>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}
