import { useAuth0 } from "@auth0/auth0-react";
import { ArrowBack, ArrowForward } from "@mui/icons-material";
import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { styled } from "@mui/styles";
import { DiversityMonitoring } from "api/clientAPI";
import { RootState } from "app/rootReducer";
import { IppSaveButton } from "components/Buttons/IppSaveButton";
import { IppFormDivider } from "components/IppFormDivider";
import { IppSkeleton } from "components/IppSkeleton";
import { updClientDiversity } from "features/client/ClientSlice";
import { openSnackBar } from "features/snackBar/SnackBarSlice";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { push } from "redux-first-history";
import { getChangedValues } from "utils/functions";

const PREFIX = "DiversityMonitoringForm";

const classes = {
  editForm: `${PREFIX}-editForm`,
  boxSpace: `${PREFIX}-boxSpace`,
  roleCard: `${PREFIX}-roleCard`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.editForm}`]: {
    minWidth: 650,
    maxWidth: 1100,
  },

  [`& .${classes.boxSpace}`]: {
    padding: 10,
  },

  [`& .${classes.roleCard}`]: {
    minWidth: 250,

    minHeight: 300,
  },
}));

// format for display lists
interface ListValue {
  name: string;
  clientObjKey: keyof DiversityMonitoring;
  isSelected: boolean | undefined;
}

export const DiversityMonitoringForm = () => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const [pageLoaded, setPageLoaded] = useState(false);

  // allGroups contains unfiltered list of groups
  // filteredGroups contains list of groups with filter applied
  // selectedGroups contains list of items selected
  const [allGroups, setAllGroups] = useState<ListValue[]>([]);
  const [filteredGroups, setFilteredGroups] = useState<ListValue[]>([]);
  const [selectedGroups, setSelectedGroups] = useState<ListValue[]>([]);
  const [filterValue, setFilterValue] = useState("");

  const {
    clientId,
    dm_WomanOwned,
    dm_VeteranOwned,
    dm_Disability,
    dm_VisibleMinority,
    dm_LGBTQ,
    dm_Local,
    dm_NorthernResident,
    ft_ben_WorkForce,
  } = useSelector((state: RootState) => state.client);

  const initialValues: DiversityMonitoring = {
    DM_WomanOwned: dm_WomanOwned,
    DM_VeteranOwned: dm_VeteranOwned,
    DM_Disability: dm_Disability,
    DM_VisibleMinority: dm_VisibleMinority,
    DM_LGBTQ: dm_LGBTQ,
    DM_Local: dm_Local,
    DM_NorthernResident: dm_NorthernResident,
  };

  const clientDiversityObj = useRef<DiversityMonitoring>({ ...initialValues });

  const diversityLabels: Record<keyof DiversityMonitoring, string> = {
    DM_WomanOwned: "Woman-Owned",
    DM_VeteranOwned: "Veteran-Owned",
    DM_Disability: "Disability",
    DM_VisibleMinority: "Visible Minority",
    DM_LGBTQ: "LGBTQ+",
    DM_Local: "Local",
    DM_NorthernResident: "Owned by Northern Resident",
  };

  useEffect(() => {
    setPageLoaded(true);
  }, [
    clientId,
    dm_WomanOwned,
    dm_VeteranOwned,
    dm_Disability,
    dm_VisibleMinority,
    dm_LGBTQ,
    dm_Local,
    dm_NorthernResident,
  ]);

  // make sure lists are initialized only one time
  useEffect(() => {
    if (pageLoaded && allGroups.length === 0) {
      initializeLists();
    }
  }, [pageLoaded]);

  const initializeLists = () => {
    // initialize full list
    let data1: ListValue[] = [];
    let data2: ListValue[] = [];
    Object.keys(clientDiversityObj.current).forEach((indicator) => {
      const key = indicator as keyof typeof clientDiversityObj.current;
      const indicatorObj: ListValue = {
        name: diversityLabels[key],
        clientObjKey: key,
        isSelected: clientDiversityObj.current[key],
      };

      if (!clientDiversityObj.current[key]) {
        data1.push(indicatorObj);
      } else if (clientDiversityObj.current[key]) {
        data2.push(indicatorObj);
      }
    });

    setAllGroups(data1);
    setFilteredGroups(data1);
    setSelectedGroups(data2);
  };

  const handleSave = async () => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
      },
    });

    const updatedValues = getChangedValues(
      clientDiversityObj.current,
      initialValues
    );

    if (Object.keys(updatedValues).length === 0) return;

    // On save, update all DM_ fields of client
    dispatch(updClientDiversity(accessToken, clientId, updatedValues, true));

    dispatch(openSnackBar("Updates completed", "success"));
    dispatch(push("/admin/diversityMonitoring"));
  };

  const handleToggleRight = (value: ListValue) => () => {
    // add item to full list, and sort it
    setAllGroups((oldArray) =>
      allGroups.concat(value).sort((a, b) => {
        let fa = a.name.toLowerCase(),
          fb = b.name.toLowerCase();

        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
        return 0;
      })
    );

    setFilteredGroups((oldArray) =>
      filteredGroups.concat(value).sort((a, b) => {
        let fa = a.name.toLowerCase(),
          fb = b.name.toLowerCase();

        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
        return 0;
      })
    );

    // remove item from selected list
    setSelectedGroups(
      selectedGroups.filter(function (a) {
        return a !== value;
      })
    );

    const key = value.clientObjKey as keyof typeof clientDiversityObj.current;
    clientDiversityObj.current[key] = false;
  };

  const handleToggleLeft = (value: ListValue) => () => {
    // add item to selected list, and sort it
    setSelectedGroups((oldArray) =>
      selectedGroups.concat(value).sort((a, b) => {
        let fa = a.name.toLowerCase(),
          fb = b.name.toLowerCase();

        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
        return 0;
      })
    );

    // remove item from full list list
    setAllGroups(
      allGroups.filter(function (a) {
        return a !== value;
      })
    );

    setFilteredGroups(
      filteredGroups.filter(function (a) {
        return a !== value;
      })
    );

    const key = value.clientObjKey as keyof typeof clientDiversityObj.current;
    clientDiversityObj.current[key] = true;
  };

  const onFilterChange = (e: any) => {
    const val = e.target.value || "";
    setFilterValue(val);
    if (val === "") {
      setFilteredGroups(allGroups);
    } else {
      setFilteredGroups(
        allGroups.filter(function (a) {
          return a.name.toLowerCase().includes(val.toLowerCase());
        })
      );
    }
  };

  let fullList = !pageLoaded ? (
    <IppSkeleton height={400} />
  ) : (
    <Paper sx={{ height: 400, overflow: "auto" }}>
      <List dense component="div" role="list">
        {filteredGroups.map((item) => {
          return (
            <Tooltip title="Click to add item to selected groups">
              <ListItem
                button
                key={item.clientObjKey}
                onClick={handleToggleLeft(item)}
                role="listitem"
              >
                <ListItemIcon>
                  <ArrowForward />
                </ListItemIcon>
                <ListItemText primary={item.name} />
              </ListItem>
            </Tooltip>
          );
        })}
      </List>
    </Paper>
  );

  let selectedList = !pageLoaded ? (
    <IppSkeleton height={400} />
  ) : (
    <Paper sx={{ height: 400, overflow: "auto" }}>
      <List dense component="div" role="list">
        {selectedGroups.map((item) => {
          return (
            <Tooltip title="Click to remove item from selected list">
              <ListItem
                button
                role="listitem"
                key={item.clientObjKey}
                onClick={handleToggleRight(item)}
              >
                <ListItemIcon>
                  <ArrowBack />
                </ListItemIcon>
                <ListItemText primary={item.name} />
              </ListItem>
            </Tooltip>
          );
        })}
      </List>
    </Paper>
  );

  return (
    <Root>
      <Box display="flex" justifyContent="center">
        <Paper className={classes.boxSpace}>
          <Grid container className={classes.editForm} spacing={2}>
            <Grid item xs={9}>
              <Typography variant="h5" component="h1">
                Diversity Monitoring Indicators
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Grid container justifyContent="flex-end">
                <IppSaveButton handleSave={handleSave} />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <IppFormDivider title="Guide" />
              <Typography display="inline">
                This option allows you to select diversity indicators you wish
                to have appear on forms. Only the items you select will be
                available to users. Selecting a diversity indicator from one
                list will move it into the other list. Once you have completed
                your changes, click
                <strong> Save Changes</strong> to finalize them.
              </Typography>
            </Grid>

            <Grid item xs={4}>
              <Typography variant="h6">Available Indicators</Typography>
            </Grid>
            <Grid item xs={2}>
              <TextField
                label="Search for..."
                value={filterValue}
                onChange={onFilterChange}
                size="small"
              />
            </Grid>

            <Grid item xs={6}>
              <Typography variant="h6">Selected Indicators</Typography>
            </Grid>
            <Grid item xs={6}>
              {fullList}
            </Grid>
            <Grid item xs={6}>
              {selectedList}
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </Root>
  );
};
