import axiosClient from "../axiosClient";

export interface InteractionType {
  InteractionTypeID: number;
  InteractionTypeName: string;
}

export interface InteractionTypesResult {
  interactionTypes: InteractionType[];
}

export async function getInteractionTypes(
  accessToken: String
): Promise<InteractionTypesResult> {
  const url = `/interactionType`;

  try {
    const { data } = await axiosClient.get<InteractionTypesResult>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return data;
  } catch (err: any) {
    throw err;
  }
}
