import { Box, Grid, Paper, Typography } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "app/rootReducer";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { fetchCommitmentActionSummarys } from "./CommitmentActionSummarySlice";
import { fetchCommitmentSummarys } from "./CommitmentSummarySlice";
import IppShadowBox from "components/IppShadowBox";
import { IppSkeleton } from "components/IppSkeleton";
import { CommitmentsByGroupChart } from "./CommitmentsByGroupChart";
import { CommitmentsPendingTable } from "./CommitmentsPendingTable";
import { useTranslation } from "react-i18next";

export const CommitmentsHomePage = () => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const { t } = useTranslation(["objPlt", "strGen", "objCom"]);
  const [commitmentCompleted, setCommitmentCompleted] = useState("");
  const [actionsCompleted, setActionsCompleted] = useState("");
  const [actionsOverdue, setActionsOverdue] = useState("");
  const [actionsOutstanding, setActionsOutstanding] = useState("");

  const { clientId, isLoading: clientIsLoading } = useSelector(
    (state: RootState) => state.client
  );

  const {
    commitmentActionSummaryList,
    commitmentActionSummarysById,
    isLoading: acIsLoading,
    error: acError,
  } = useSelector((state: RootState) => state.commitmentActionSummarys);

  const commitmentActionSummarys = commitmentActionSummaryList.map(
    (id) => commitmentActionSummarysById[id]
  );

  const {
    commitmentSummaryList,
    commitmentSummarysById,
    isLoading: sumIsLoading,
    error: sumError,
  } = useSelector((state: RootState) => state.commitmentSummarys);

  const commitmentSummarys = commitmentSummaryList.map(
    (id) => commitmentSummarysById[id]
  );

  useEffect(() => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        dispatch(fetchCommitmentActionSummarys(accessToken));
        dispatch(fetchCommitmentSummarys(accessToken));
      } catch (e) {
        console.error(e);
      }
    })();
  }, [dispatch, getAccessTokenSilently]);

  useEffect(() => {
    if (commitmentSummarys.length > 0 && commitmentCompleted === "") {
      let _total =
        commitmentSummarys[0].Completed + commitmentSummarys[0].Outstanding;
      setCommitmentCompleted(
        commitmentSummarys[0].Completed.toString() + "/" + _total.toString()
      );
    }
  }, [commitmentSummarys, commitmentCompleted]);

  useEffect(() => {
    if (commitmentActionSummarys.length > 0 && actionsCompleted === "") {
      setActionsCompleted(commitmentActionSummarys[0].Completed.toString());
      setActionsOutstanding(commitmentActionSummarys[0].Outstanding.toString());
      setActionsOverdue(commitmentActionSummarys[0].Overdue.toString());
    }
  }, [commitmentActionSummarys, actionsCompleted]);

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper>
            <Box padding={1}>
              <Typography variant="h5" component="h1">
                {t("objCom:objects.commitment.commitmentshome", { count: 2 })}{" "}
              </Typography>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={6} md={3}>
          {sumIsLoading ? (
            <IppSkeleton height={160} />
          ) : (
            <IppShadowBox
              title={t("objCom:objects.commitment.commitmentscompleted", {
                count: 2,
              })}
              value={commitmentCompleted}
              colorSet="green"
              pageName="/commitments/commitments"
              filter={{
                filters: [
                  {
                    logic: "or",
                    filters: [
                      {
                        field: "CommitmentStatusTypeName",
                        operator: "eq",
                        value: t(
                          "objCom:objects.commitment.statustype.completed"
                        ),
                      },
                      {
                        field: "CommitmentStatusTypeName",
                        operator: "eq",
                        value: t(
                          "objCom:objects.commitment.statustype.actionnotyetrequired"
                        ),
                      },
                      {
                        field: "CommitmentStatusTypeName",
                        operator: "eq",
                        value: t(
                          "objCom:objects.commitment.statustype.completecommitmentmet"
                        ),
                      },
                      {
                        field: "CommitmentStatusTypeName",
                        operator: "eq",
                        value: t(
                          "objCom:objects.commitment.statustype.completenotrequired"
                        ),
                      },
                    ],
                  },
                ],
                logic: "and",
              }}
            />
          )}
        </Grid>
        <Grid item xs={6} md={3}>
          {acIsLoading ? (
            <IppSkeleton height={160} />
          ) : (
            <IppShadowBox
              title={t("objCom:objects.commitmentaction.actionsoverdue", {
                count: 2,
              })}
              value={actionsOverdue}
              colorSet="red"
              pageName={"/commitments/actions"}
              filter={{
                filters: [
                  {
                    logic: "and",
                    filters: [
                      {
                        field: "RequiredDate",
                        operator: "lt",
                        value: new Date(),
                      },
                    ],
                  },
                  {
                    logic: "or",
                    filters: [
                      {
                        field: "ActionStatusName",
                        operator: "eq",
                        value: t(
                          "objCom:objects.commitment.statustype.notstarted"
                        ),
                      },
                      {
                        field: "ActionStatusName",
                        operator: "eq",
                        value: t(
                          "objCom:objects.commitment.statustype.inprogress"
                        ),
                      },
                    ],
                  },
                ],
                logic: "and",
              }}
            />
          )}
        </Grid>
        <Grid item xs={6} md={3}>
          {acIsLoading ? (
            <IppSkeleton height={160} />
          ) : (
            <IppShadowBox
              title={t("objCom:objects.commitmentaction.actionscompleted", {
                count: 2,
              })}
              value={actionsCompleted}
              colorSet="blue"
              pageName={"/commitments/actions"}
              filter={{
                filters: [
                  {
                    logic: "or",
                    filters: [
                      {
                        field: "ActionStatusName",
                        operator: "eq",
                        value: t(
                          "objCom:objects.commitment.statustype.closedcompleted"
                        ),
                      },
                      {
                        field: "ActionStatusName",
                        operator: "eq",
                        value: t(
                          "objCom:objects.commitment.statustype.closedincomplete"
                        ),
                      },
                      {
                        field: "ActionStatusName",
                        operator: "eq",
                        value: t(
                          "objCom:objects.commitment.statustype.nolongerneeded"
                        ),
                      },
                    ],
                  },
                ],
                logic: "and",
              }}
            />
          )}
        </Grid>
        <Grid item xs={6} md={3}>
          {acIsLoading ? (
            <IppSkeleton height={160} />
          ) : (
            <IppShadowBox
              title={t("objCom:objects.commitmentaction.actionsoutstanding", {
                count: 2,
              })}
              value={actionsOutstanding}
              pageName={"/commitments/actions"}
              filter={{
                filters: [
                  {
                    logic: "or",
                    filters: [
                      {
                        field: "ActionStatusName",
                        operator: "eq",
                        value: t(
                          "objCom:objects.commitment.statustype.notstarted"
                        ),
                      },
                      {
                        field: "ActionStatusName",
                        operator: "eq",
                        value: t(
                          "objCom:objects.commitment.statustype.inprogress"
                        ),
                      },
                    ],
                  },
                ],
                logic: "and",
              }}
            />
          )}
        </Grid>
        <Grid item xs={12} md={4}>
          <IppShadowBox
            title={t("objCom:objects.commitment.commitmentsbygroup", {
              commitmentcount: 2,
            })}
            component={<CommitmentsByGroupChart />}
            padding={2}
          />
        </Grid>
        <Grid item xs={12} md={8}>
          <IppShadowBox
            title=""
            component={<CommitmentsPendingTable />}
            padding={2}
          />
        </Grid>
      </Grid>
    </div>
  );
};
