import { useFormik } from "formik";
import { Box, Dialog, DialogContent, DialogTitle, Grid } from "@mui/material";
import { IppTextField } from "components/IppTextField";
import { IppMultiTextField } from "components/IppMultiTextField";
import { useTypedTranslation } from "utils/customHooks";
import { TagValidationSchema } from "./TagValidation";
import { Tag } from "api/tagAPI";
import { useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { addTag, updTag } from "./TagSlice";
import { IppFormButtons } from "components/Buttons/IppFormButtons";
import { VALID_RECORD_TYPES } from "utils/constants/generalConstants";

interface TagFormProps {
  editOpen: boolean;
  setEditOpen: (value: boolean) => void;
  setEditID: (value: number | undefined) => void;
  isAdding: boolean;
  setIsAdding: (value: boolean) => void;
  tags: Tag[];
  tagData: Tag | undefined;
}

export const TagForm = (props: TagFormProps) => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const t = useTypedTranslation(["objPlt", "strGen", "objCom", "objStk"]);

  const {
    editOpen,
    setEditOpen,
    setEditID,
    isAdding,
    setIsAdding,
    tags,
    tagData,
  } = props;

  const tagID = tagData?.TagID ?? undefined;

  const onSub = (values: any) => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });

        if (tagID) {
          dispatch(updTag(accessToken, tagID, values));
        } else {
          dispatch(addTag(accessToken, values));
        }
        setEditOpen(false);
      } catch (e) {
        console.error(e);
      }
    })();
  };

  const initialValues = tagData || {
    Name: "",
    RecordType: "",
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: TagValidationSchema({
      tags: tags,
      tagID: tagID,
    }),
    onSubmit: onSub,
    enableReinitialize: true,
  });

  const handleEditClose = () => {
    setEditOpen(false);
    if (isAdding) setIsAdding(false);
    if (tagID) setEditID(undefined);
    formik.resetForm();
  };

  const editForm = (
    <Dialog open={editOpen} onClose={handleEditClose} maxWidth="md" fullWidth>
      <DialogTitle>
        {isAdding
          ? t("strGen:prompts.add.addnewtitle", {
              fieldname: t("objPlt:objects.tag.name"),
            })
          : t("strGen:prompts.edit.edittitle", {
              fieldname: t("objPlt:objects.tag.name"),
            })}
      </DialogTitle>
      <DialogContent>
        <form noValidate onSubmit={formik.handleSubmit}>
          <Grid container spacing={1}>
            <Grid item xs={5}>
              <IppTextField
                id="Name"
                label={t("objPlt:objects.tag.fields.tagname")}
                required={true}
                value={formik.values.Name}
                onChangeFunction={formik.handleChange}
                errorsExpression={formik.errors.Name}
                touchedExpression={formik.touched.Name}
                isEditing={true}
                setIsEditing={null}
              />
            </Grid>
            <Grid item xs={7}>
              <IppMultiTextField
                id="RecordType"
                label={t("objPlt:objects.tag.fields.recordtype")}
                required={true}
                options={VALID_RECORD_TYPES}
                noOptionsText={t("strGen:components.nooptionstext")}
                value={formik.values.RecordType}
                setValue={(newValue: any) =>
                  formik.setFieldValue("RecordType", newValue.toString())
                }
                touchedFunction={formik.touched.RecordType}
                errorFunction={formik.errors.RecordType}
                isEditing={true}
                setIsEditing={null}
                multiple={true}
                autoPopulate={false}
                freeSolo={false}
              />
            </Grid>
            <Grid item xs={12}>
              <IppFormButtons
                isEditing={true}
                setIsEditing={null}
                isAdding={isAdding}
                showCancel={true}
                resetFunction={handleEditClose}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );

  return <Box>{editForm}</Box>;
};
