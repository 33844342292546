import { useFormik } from "formik";
import { styled } from "@mui/material/styles";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "app/rootReducer";
import { useAuth0 } from "@auth0/auth0-react";
import { Box, Grid, IconButton, Paper, Typography } from "@mui/material";
import {
  fetchStakeholderGroups,
  mrgStakeholderGroup,
} from "./StakeholderGroupSlice";
import { ChangeEvent, useEffect, useState } from "react";
import { useParams } from "react-router";
import { ArrowLeft, ArrowRight } from "@mui/icons-material";
import { IppDisplayField } from "components/IppDisplayField";
import { IppSkeleton } from "components/IppSkeleton";
import { IppRating } from "components/IppRating";
import { IppTextField } from "components/IppTextField";
import { IppAutocomplete } from "components/IppAutocomplete";
import { ValidationSchema } from "./StakeholderGroupValidation";
import { fetchOrganizationTypes } from "features/datalists/OrganizationTypeSlice";
import { User } from "api/usersAPI";
import { UserRole } from "api/userRoleAPI";
import { ClientModule } from "api/clientModuleAPI";
import { fetchIndigenousGroupsByClient } from "features/datalists/IndigenousGroupSlice";
import { fetchUsers } from "features/users/UsersSlice";
import { IppCheckbox } from "components/IppCheckbox";
import { StakeholderGroup } from "api/stakeholder/stakeholderGroupAPI";
import { IppMultiTextField } from "components/IppMultiTextField";
import { fetchProximityIndicators } from "features/datalists/ProximityIndicatorSlice";
import { useSnackBarConstants, useTypedTranslation } from "utils/customHooks";
import { IppDisplayChip } from "components/IppDisplayChip";
import { PersonRenderOption } from "utils/renderFunctions";
import { mergeProximityIndicatorLSDs } from "utils/functions";
import { IppLocationAutoCompleteMerge } from "components/IppLocationAutoCompleteMerge";
import { IppSubmitButton } from "components/Buttons/IppSubmitButton";
import { selectAllUserRoles } from "features/roles/UserRoleSlice";

interface StakeholderGroupMergeProps {
  originalID: string;
  newID: string;
}

const PREFIX = "StakeholderGroupMerge";

const classes = {
  editForm: `${PREFIX}-editForm`,
  boxSpace: `${PREFIX}-boxSpace`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.editForm}`]: {
    // minWidth: 650,
    maxWidth: 1150,
  },

  [`& .${classes.boxSpace}`]: {
    padding: theme.spacing(1),
  },
}));

export const StakeholderGroupMergePage = () => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const t = useTypedTranslation(["objPlt", "strGen"]);
  const snackbarConstants = useSnackBarConstants();
  const [validationOK, setValidationOK] = useState(true);

  const { originalID, newID } = useParams<StakeholderGroupMergeProps>();
  const originalID_int = parseInt(originalID, 10);
  const newID_int = parseInt(newID, 10);
  if (typeof originalID_int != "number" && typeof newID_int != "number") {
    setValidationOK(false);
  }

  const { currentProfile } = useSelector((state: RootState) => state.profile);

  const {
    stakeholderGroupList,
    stakeholderGroupsById,
    isLoading: groupIsLoading,
  } = useSelector((state: RootState) => state.stakeholderGroups);

  const stakeholdergroups = stakeholderGroupList.map(
    (id) => stakeholderGroupsById[id]
  );

  const { ft_eng_lvlOfEngagement, ft_eng_PILSD } = useSelector(
    (state: RootState) => state.client
  );

  const {
    organizationTypeList,
    organizationTypesById,
    error: organizationTypeError,
    isLoading: organizationTypeIsLoading,
  } = useSelector((state: RootState) => state.organizationTypes);

  const organizationTypes = organizationTypeList.map(
    (id) => organizationTypesById[id]
  );

  const { clientModuleList, clientModulesById } = useSelector(
    (state: RootState) => state.clientModules
  );

  const clientmodules = clientModuleList.map((id) => clientModulesById[id]);

  const { userList, usersById } = useSelector(
    (state: RootState) => state.users
  );

  const userRoles = useSelector((state: RootState) =>
    selectAllUserRoles(state)
  );

  // Filter for active users only
  const allUsers = userList.map((id) => usersById[id]);
  let users = allUsers.filter((item: User) => {
    if (!item.IsActive || item.CompanyID != currentProfile.CompanyID)
      return false;
    if (item.IsClientAdmin) {
      return true;
    } else {
      let found = userRoles.filter((role) => {
        return item.UserAccountID === role.UserAccountID;
      });
      if (found && found.length > 0) {
        let check = clientmodules.find((object: ClientModule) => {
          let clientMod = found.find((mod: UserRole) => {
            return (
              object.ClientModuleID === mod.ClientModuleID &&
              (object.ModuleID === 3 || object.ModuleID === 2)
            );
          });
          return clientMod;
        });
        return check;
      } else return false;
    }
  });

  const {
    indigenousGroupList,
    indigenousGroupsById,
    error: indigenousGroupError,
    isLoading: indigenousGroupIsLoading,
  } = useSelector((state: RootState) => state.indigenousGroups);

  const indigenousGroups = indigenousGroupList.map(
    (id) => indigenousGroupsById[id]
  );

  const { clientId, ft_eng_LegalSubDiv } = useSelector(
    (state: RootState) => state.client
  );

  const { proximityIndicatorList, proximityIndicatorsById } = useSelector(
    (state: RootState) => state.proximityIndicators
  );

  const proximityIndicators = proximityIndicatorList.map(
    (id) => proximityIndicatorsById[id].ProximityIndicatorName
  );

  useEffect(() => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        if (stakeholdergroups.length === 0) {
          dispatch(fetchStakeholderGroups(accessToken));
        }
        dispatch(fetchOrganizationTypes(accessToken));
        dispatch(fetchIndigenousGroupsByClient(accessToken));
        dispatch(fetchUsers(accessToken, clientId));
        dispatch(fetchProximityIndicators(accessToken));
        if (ft_eng_PILSD) {
          dispatch(fetchProximityIndicators(accessToken));
        }
      } catch (e) {
        console.error(e);
      }
    })();
  }, [stakeholdergroups.length, dispatch, getAccessTokenSilently]);

  const onSub = (values: any) => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        if (ft_eng_PILSD) {
          values = {
            ...values,
            ProximityIndicatorLSDs: values.ProximityIndicatorLSDs?.filter(
              (pilsd: any) =>
                pilsd.ProximityIndicator !== "" || pilsd.LegalSubdivision !== ""
            ),
          };
        }
        dispatch(
          mrgStakeholderGroup(
            accessToken,
            originalID_int,
            values,
            true,
            newID_int,
            snackbarConstants
          )
        );
      } catch (e) {
        console.error(e);
      }
    })();
  };

  const oldGroup: StakeholderGroup =
    stakeholderGroupsById[Number(originalID_int)];
  const dupeGroup: StakeholderGroup = stakeholderGroupsById[Number(newID_int)];
  const mergeGroup: { [key: string]: any } = { ...oldGroup };

  for (const [key, value] of Object.entries(mergeGroup)) {
    if (!value) {
      mergeGroup[key] = dupeGroup[key as keyof StakeholderGroup];
    }
    if (
      key === "ProximityIndicatorLSDs" &&
      (oldGroup.ProximityIndicatorLSDs || dupeGroup.ProximityIndicatorLSDs)
    ) {
      mergeGroup[key] = mergeProximityIndicatorLSDs(
        oldGroup?.ProximityIndicatorLSDs,
        dupeGroup?.ProximityIndicatorLSDs
      );
    }
  }

  const strictMergeGroup: StakeholderGroup = mergeGroup as StakeholderGroup;

  const formik = useFormik({
    initialValues: strictMergeGroup,
    validationSchema: ValidationSchema(
      strictMergeGroup,
      stakeholdergroups,
      oldGroup,
      dupeGroup
    ),
    onSubmit: onSub,
  });

  useEffect(() => {
    if (formik.values) {
      // Reset values for Indigenous Group if Indigenous becomes false
      if (formik.values.Indigenous === false) {
        formik.setFieldValue("IndigenousGroupID", -1);
      }
    }
  }, [formik.values]);

  let formView =
    groupIsLoading ||
    organizationTypeIsLoading ||
    indigenousGroupIsLoading ||
    !oldGroup ||
    !formik.values ? (
      <IppSkeleton height={400} />
    ) : (
      <form onSubmit={formik.handleSubmit}>
        <Paper elevation={6} className={classes.boxSpace}>
          <Box padding={1} sx={{ borderRadius: 1 }}>
            <Grid container spacing={0}>
              <Grid item xs={12} container justifyContent="flex-end">
                <IppSubmitButton buttonText={t("strGen:buttons.save")} />
              </Grid>
              <Grid item xs={4}>
                <Grid container spacing={0}>
                  <Grid item xs={12}>
                    <Typography variant="h6" gutterBottom>
                      {t("strGen:pages.merge.originalvalues", {
                        fieldname: t("objPlt:objects.group.name"),
                      })}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <Grid container spacing={0}>
                  <Grid item xs={8}>
                    <Typography variant="h6" gutterBottom>
                      {t("strGen:pages.merge.mergedvalues", {
                        fieldname: t("objPlt:objects.group.name"),
                      })}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <Grid container spacing={0}>
                  <Grid item xs={12}>
                    <Typography variant="h6" gutterBottom>
                      {t("strGen:pages.merge.duplicatevalues", {
                        fieldname: t("objPlt:objects.group.name"),
                      })}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              {/*  grid block */}
              <Grid item xs={4}>
                <Grid container spacing={0}>
                  <Grid item xs={10}>
                    <IppDisplayField
                      label={t("objPlt:objects.group.fields.name")}
                      value={oldGroup.GroupName}
                      isEditing={false}
                      setIsEditing={null}
                      showEdit={false}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <IconButton
                      onClick={() =>
                        formik.setFieldValue("GroupName", oldGroup.GroupName)
                      }
                    >
                      <ArrowRight />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <IppTextField
                  id="GroupName"
                  label={t("objPlt:objects.group.fields.name")}
                  required={true}
                  value={formik.values.GroupName}
                  onChangeFunction={formik.handleChange}
                  touchedExpression={formik.touched.GroupName}
                  errorsExpression={formik.errors.GroupName}
                  isEditing={true}
                  setIsEditing={null}
                />
              </Grid>
              <Grid item xs={4}>
                <Grid container spacing={0}>
                  <Grid item xs={2}>
                    <IconButton
                      onClick={() =>
                        formik.setFieldValue("GroupName", dupeGroup.GroupName)
                      }
                    >
                      <ArrowLeft />
                    </IconButton>
                  </Grid>
                  <Grid item xs={10}>
                    <IppDisplayField
                      label={t("objPlt:objects.group.fields.name")}
                      value={dupeGroup.GroupName}
                      isEditing={false}
                      setIsEditing={null}
                      showEdit={false}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <Grid container spacing={0}>
                  <Grid item xs={10}>
                    <IppDisplayField
                      label={t("objPlt:objects.group.fields.organizationtype")}
                      value={oldGroup.OrganizationTypeName}
                      isEditing={false}
                      setIsEditing={null}
                      showEdit={false}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <IconButton
                      onClick={() =>
                        formik.setFieldValue(
                          "OrganizationTypeID",
                          oldGroup.OrganizationTypeID
                        )
                      }
                    >
                      <ArrowRight />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <IppAutocomplete
                  id="OrganizationTypeID"
                  options={organizationTypes}
                  value={
                    organizationTypes.find((obj) => {
                      return (
                        obj.OrganizationTypeID ===
                        formik.values.OrganizationTypeID
                      );
                    }) || null
                  }
                  onChangeFunction={(event: ChangeEvent, newValue: any) => {
                    if (newValue && newValue !== null) {
                      formik.setFieldValue(
                        "OrganizationTypeID",
                        newValue.OrganizationTypeID
                      );
                    } else {
                      formik.setFieldValue("OrganizationTypeID", null);
                    }
                  }}
                  label={t("objPlt:objects.group.fields.organizationtype")}
                  required={true}
                  touchedFunction={formik.touched.OrganizationTypeID}
                  errorFunction={formik.errors.OrganizationTypeID}
                  isEditing={true}
                  setIsEditing={null}
                  optionLabelFunction={(option: any) =>
                    option.OrganizationTypeName
                  }
                  textValueFunction={
                    !organizationTypeIsLoading &&
                    !organizationTypeError &&
                    formik.values.OrganizationTypeID > 0 &&
                    organizationTypesById[formik.values.OrganizationTypeID]
                      ? organizationTypesById[formik.values.OrganizationTypeID]
                          .OrganizationTypeName
                      : ""
                  }
                  toolTip={t("strGen:tooltips.group.fields.organizationtype")}
                />
              </Grid>
              <Grid item xs={4}>
                <Grid container spacing={0}>
                  <Grid item xs={2}>
                    <IconButton
                      onClick={() =>
                        formik.setFieldValue(
                          "OrganizationTypeID",
                          dupeGroup.OrganizationTypeID
                        )
                      }
                    >
                      <ArrowLeft />
                    </IconButton>
                  </Grid>
                  <Grid item xs={10}>
                    <IppDisplayField
                      label={t("objPlt:objects.group.fields.organizationtype")}
                      value={dupeGroup.OrganizationTypeName}
                      isEditing={false}
                      setIsEditing={null}
                      showEdit={false}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={4}>
                <Grid container spacing={0}>
                  <Grid item xs={10}>
                    <IppCheckbox
                      id="Indigenous"
                      label={t("objPlt:objects.group.fields.indigenous") + "?"}
                      value={oldGroup.Indigenous}
                      onChangeFunction={formik.handleChange}
                      isEditing={false}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <IconButton
                      onClick={() =>
                        formik.setFieldValue("Indigenous", oldGroup.Indigenous)
                      }
                    >
                      <ArrowRight />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <IppCheckbox
                  id="Indigenous"
                  label={t("objPlt:objects.group.fields.indigenous") + "?"}
                  value={formik.values.Indigenous}
                  onChangeFunction={formik.handleChange}
                  isEditing={true}
                />
              </Grid>
              <Grid item xs={4}>
                <Grid container spacing={0}>
                  <Grid item xs={2}>
                    <IconButton
                      onClick={() =>
                        formik.setFieldValue("Indigenous", dupeGroup.Indigenous)
                      }
                    >
                      <ArrowLeft />
                    </IconButton>
                  </Grid>
                  <Grid item xs={10}>
                    <IppCheckbox
                      id="Indigenous"
                      label={t("objPlt:objects.group.fields.indigenous") + "?"}
                      value={dupeGroup.Indigenous}
                      onChangeFunction={formik.handleChange}
                      isEditing={false}
                    />
                  </Grid>
                </Grid>
              </Grid>

              {formik.values.Indigenous && (
                <>
                  <Grid item xs={4}>
                    <Grid container spacing={0}>
                      <Grid item xs={10}>
                        <IppDisplayField
                          label={t(
                            "objPlt:objects.group.fields.indigenousgroup"
                          )}
                          value={
                            indigenousGroupsById &&
                            indigenousGroupsById[oldGroup.IndigenousGroupID]
                              ? indigenousGroupsById[oldGroup.IndigenousGroupID]
                                  .IndigenousGroupName
                              : ""
                          }
                          isEditing={false}
                          setIsEditing={null}
                          showEdit={false}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <IconButton
                          onClick={() =>
                            formik.setFieldValue(
                              "IndigenousGroupID",
                              oldGroup.IndigenousGroupID
                            )
                          }
                        >
                          <ArrowRight />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={4}>
                    <IppAutocomplete
                      id="IndigenousGroupID"
                      options={indigenousGroups}
                      value={
                        indigenousGroups.find((obj) => {
                          return (
                            obj.IndigenousGroupID ===
                            formik.values.IndigenousGroupID
                          );
                        }) || null
                      }
                      onChangeFunction={(event: ChangeEvent, newValue: any) => {
                        if (newValue) {
                          formik.setFieldValue(
                            "IndigenousGroupID",
                            newValue.IndigenousGroupID
                          );
                        } else {
                          formik.setFieldValue("IndigenousGroupID", -1);
                        }
                      }}
                      label={t("objPlt:objects.group.fields.indigenousgroup")}
                      isEditing={true}
                      setIsEditing={null}
                      optionLabelFunction={(option: any) =>
                        option.IndigenousGroupName
                      }
                      touchedFunction={formik.touched.IndigenousGroupID}
                      errorFunction={formik.errors.IndigenousGroupID}
                      textValueFunction={
                        !indigenousGroupIsLoading &&
                        !indigenousGroupError &&
                        indigenousGroups.length > 0 &&
                        formik.values.IndigenousGroupID > 0 &&
                        indigenousGroupsById[formik.values.IndigenousGroupID]
                          ? indigenousGroupsById[
                              formik.values.IndigenousGroupID
                            ].IndigenousGroupName
                          : ""
                      }
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Grid container spacing={0}>
                      <Grid item xs={2}>
                        <IconButton
                          onClick={() =>
                            formik.setFieldValue(
                              "IndigenousGroupID",
                              dupeGroup.IndigenousGroupID
                            )
                          }
                        >
                          <ArrowLeft />
                        </IconButton>
                      </Grid>
                      <Grid item xs={10}>
                        <IppDisplayField
                          label={t(
                            "objPlt:objects.group.fields.indigenousgroup"
                          )}
                          value={
                            indigenousGroupsById &&
                            indigenousGroupsById[dupeGroup.IndigenousGroupID]
                              ? indigenousGroupsById[
                                  dupeGroup.IndigenousGroupID
                                ].IndigenousGroupName
                              : ""
                          }
                          isEditing={false}
                          setIsEditing={null}
                          showEdit={false}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              )}

              <Grid item xs={4}>
                <Grid container spacing={0}>
                  <Grid item xs={10}>
                    <IppDisplayField
                      label={t("objPlt:objects.group.fields.details")}
                      value={oldGroup.GroupDetails}
                      isEditing={false}
                      setIsEditing={null}
                      showEdit={false}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <IconButton
                      onClick={() =>
                        formik.setFieldValue(
                          "GroupDetails",
                          oldGroup.GroupDetails
                        )
                      }
                    >
                      <ArrowRight />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <IppTextField
                  id="GroupDetails"
                  label={t("objPlt:objects.group.fields.details")}
                  required={false}
                  value={formik.values.GroupDetails}
                  onChangeFunction={formik.handleChange}
                  touchedExpression={formik.touched.GroupDetails}
                  errorsExpression={formik.errors.GroupDetails}
                  isEditing={true}
                  setIsEditing={null}
                />
              </Grid>
              <Grid item xs={4}>
                <Grid container spacing={0}>
                  <Grid item xs={2}>
                    <IconButton
                      onClick={() =>
                        formik.setFieldValue(
                          "GroupDetails",
                          dupeGroup.GroupDetails
                        )
                      }
                    >
                      <ArrowLeft />
                    </IconButton>
                  </Grid>
                  <Grid item xs={10}>
                    <IppDisplayField
                      label={t("objPlt:objects.group.fields.details")}
                      value={dupeGroup.GroupDetails}
                      isEditing={false}
                      setIsEditing={null}
                      showEdit={false}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <IppLocationAutoCompleteMerge
                formik={formik}
                oldValues={oldGroup}
                dupeValues={dupeGroup}
              />

              <Grid item xs={4}>
                <Grid container spacing={0}>
                  <Grid item xs={10}>
                    <IppDisplayField
                      label={t("objPlt:objects.group.fields.website")}
                      value={oldGroup.WebsiteAddress}
                      isEditing={false}
                      setIsEditing={null}
                      showEdit={false}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <IconButton
                      onClick={() =>
                        formik.setFieldValue(
                          "WebsiteAddress",
                          oldGroup.WebsiteAddress
                        )
                      }
                    >
                      <ArrowRight />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <IppTextField
                  id="WebsiteAddress"
                  label={t("objPlt:objects.group.fields.website")}
                  required={false}
                  value={formik.values.WebsiteAddress}
                  onChangeFunction={formik.handleChange}
                  touchedExpression={formik.touched.WebsiteAddress}
                  errorsExpression={formik.errors.WebsiteAddress}
                  isEditing={true}
                  setIsEditing={null}
                />
              </Grid>
              <Grid item xs={4}>
                <Grid container spacing={0}>
                  <Grid item xs={2}>
                    <IconButton
                      onClick={() =>
                        formik.setFieldValue(
                          "WebsiteAddress",
                          dupeGroup.WebsiteAddress
                        )
                      }
                    >
                      <ArrowLeft />
                    </IconButton>
                  </Grid>
                  <Grid item xs={10}>
                    <IppDisplayField
                      label={t("objPlt:objects.group.fields.website")}
                      value={dupeGroup.WebsiteAddress}
                      isEditing={false}
                      setIsEditing={null}
                      showEdit={false}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <Grid container spacing={0}>
                  <Grid item xs={10}>
                    <IppDisplayField
                      label={t("objPlt:objects.group.fields.phonenumber")}
                      value={oldGroup.PhoneNumber}
                      isEditing={false}
                      setIsEditing={null}
                      showEdit={false}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <IconButton
                      onClick={() =>
                        formik.setFieldValue(
                          "PhoneNumber",
                          oldGroup.PhoneNumber
                        )
                      }
                    >
                      <ArrowRight />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <IppTextField
                  id="PhoneNumber"
                  label={t("objPlt:objects.group.fields.phonenumber")}
                  required={false}
                  value={formik.values.PhoneNumber}
                  onChangeFunction={formik.handleChange}
                  touchedExpression={formik.touched.PhoneNumber}
                  errorsExpression={formik.errors.PhoneNumber}
                  isEditing={true}
                  setIsEditing={null}
                />
              </Grid>
              <Grid item xs={4}>
                <Grid container spacing={0}>
                  <Grid item xs={2}>
                    <IconButton
                      onClick={() =>
                        formik.setFieldValue(
                          "PhoneNumber",
                          dupeGroup.PhoneNumber
                        )
                      }
                    >
                      <ArrowLeft />
                    </IconButton>
                  </Grid>
                  <Grid item xs={10}>
                    <IppDisplayField
                      label={t("objPlt:objects.group.fields.phonenumber")}
                      value={dupeGroup.PhoneNumber}
                      isEditing={false}
                      setIsEditing={null}
                      showEdit={false}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <Grid container spacing={0}>
                  <Grid item xs={10}>
                    <IppDisplayChip
                      label={t("objPlt:objects.group.fields.email")}
                      value={oldGroup.GroupEmail}
                      isEditing={false}
                      setIsEditing={null}
                      showEdit={false}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <IconButton
                      onClick={() =>
                        formik.setFieldValue("GroupEmail", oldGroup.GroupEmail)
                      }
                    >
                      <ArrowRight />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <IppTextField
                  id="GroupEmail"
                  label={t("objPlt:objects.group.fields.email")}
                  required={false}
                  value={formik.values.GroupEmail}
                  onChangeFunction={formik.handleChange}
                  touchedExpression={formik.touched.GroupEmail}
                  errorsExpression={formik.errors.GroupEmail}
                  isEditing={true}
                  setIsEditing={null}
                />
              </Grid>
              <Grid item xs={4}>
                <Grid container spacing={0}>
                  <Grid item xs={2}>
                    <IconButton
                      onClick={() =>
                        formik.setFieldValue("GroupEmail", dupeGroup.GroupEmail)
                      }
                    >
                      <ArrowLeft />
                    </IconButton>
                  </Grid>
                  <Grid item xs={10}>
                    <IppDisplayChip
                      label={t("objPlt:objects.group.fields.email")}
                      value={dupeGroup.GroupEmail}
                      isEditing={false}
                      setIsEditing={null}
                      showEdit={false}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <Grid container spacing={0}>
                  <Grid item xs={10}>
                    <IppDisplayField
                      label={t("objPlt:objects.group.fields.relationshipowner")}
                      value={
                        usersById && usersById[oldGroup.RelationshipOwner]
                          ? usersById[oldGroup.RelationshipOwner].FirstName +
                            " " +
                            usersById[oldGroup.RelationshipOwner].Surname
                          : ""
                      }
                      isEditing={false}
                      setIsEditing={null}
                      showEdit={false}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <IconButton
                      onClick={() =>
                        formik.setFieldValue(
                          "RelationshipOwner",
                          oldGroup.RelationshipOwner
                        )
                      }
                    >
                      <ArrowRight />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <IppAutocomplete
                  id="RelationshipOwner"
                  options={users}
                  renderOption={(props: any, option: User) =>
                    PersonRenderOption(props, option, [option.EmailAddress], "")
                  }
                  value={
                    allUsers.find((obj) => {
                      return (
                        obj.UserAccountID === formik.values.RelationshipOwner
                      );
                    }) || null
                  }
                  textValueFunction={
                    usersById && usersById[formik.values.RelationshipOwner]
                      ? usersById[formik.values.RelationshipOwner].FirstName +
                        " " +
                        usersById[formik.values.RelationshipOwner].Surname
                      : ""
                  }
                  onChangeFunction={(event: ChangeEvent, newValue: any) => {
                    if (newValue) {
                      formik.setFieldValue(
                        "RelationshipOwner",
                        newValue.UserAccountID
                      );
                    } else {
                      formik.setFieldValue("RelationshipOwner", null);
                    }
                  }}
                  optionLabelFunction={(option: any) =>
                    option.FirstName + " " + option.Surname
                  }
                  touchedFunction={formik.touched.RelationshipOwner}
                  errorFunction={formik.errors.RelationshipOwner}
                  label={t("objPlt:objects.group.fields.relationshipowner")}
                  isEditing={true}
                  setIsEditing={null}
                />
              </Grid>
              <Grid item xs={4}>
                <Grid container spacing={0}>
                  <Grid item xs={2}>
                    <IconButton
                      onClick={() =>
                        formik.setFieldValue(
                          "RelationshipOwner",
                          dupeGroup.RelationshipOwner
                        )
                      }
                    >
                      <ArrowLeft />
                    </IconButton>
                  </Grid>
                  <Grid item xs={10}>
                    <IppDisplayField
                      label={t("objPlt:objects.group.fields.relationshipowner")}
                      value={
                        usersById && usersById[dupeGroup.RelationshipOwner]
                          ? usersById[dupeGroup.RelationshipOwner].FirstName +
                            " " +
                            usersById[dupeGroup.RelationshipOwner].Surname
                          : ""
                      }
                      isEditing={false}
                      setIsEditing={null}
                      showEdit={false}
                    />
                  </Grid>
                </Grid>
              </Grid>

              {ft_eng_LegalSubDiv && !ft_eng_PILSD && (
                <>
                  <Grid item xs={4}>
                    <Grid container spacing={0}>
                      <Grid item xs={10}>
                        <IppDisplayChip
                          label={t(
                            "objPlt:objects.group.fields.legalsubdivision"
                          )}
                          value={oldGroup.LegalSubdivision}
                          isEditing={false}
                          setIsEditing={null}
                          showEdit={false}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <IconButton
                          onClick={() => {
                            oldGroup.LegalSubdivision
                              ? formik.setFieldValue(
                                  "LegalSubdivision",
                                  oldGroup.LegalSubdivision
                                )
                              : formik.setFieldValue("LegalSubdivision", null);
                          }}
                        >
                          <ArrowRight />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={4}>
                    <IppMultiTextField
                      id="LegalSubdivision"
                      label={t("objPlt:objects.group.fields.legalsubdivision")}
                      required={false}
                      options={[]}
                      noOptionsText={t("strGen:components.nooptionstext")}
                      value={formik.values.LegalSubdivision}
                      setValue={(newValue: any) =>
                        formik.setFieldValue(
                          "LegalSubdivision",
                          newValue.toString()
                        )
                      }
                      touchedFunction={formik.touched.LegalSubdivision}
                      errorFunction={formik.errors.LegalSubdivision}
                      isEditing={true}
                      setIsEditing={null}
                      multiple={true}
                      autoPopulate={false}
                      freeSolo={true}
                      toolTip={t("strGen:tooltips.legalsubdivision")}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Grid container spacing={0}>
                      <Grid item xs={2}>
                        <IconButton
                          onClick={() => {
                            dupeGroup.LegalSubdivision
                              ? formik.setFieldValue(
                                  "LegalSubdivision",
                                  dupeGroup.LegalSubdivision
                                )
                              : formik.setFieldValue("LegalSubdivision", null);
                          }}
                        >
                          <ArrowLeft />
                        </IconButton>
                      </Grid>
                      <Grid item xs={10}>
                        <IppDisplayChip
                          label={t(
                            "objPlt:objects.group.fields.legalsubdivision"
                          )}
                          value={dupeGroup.LegalSubdivision}
                          isEditing={false}
                          setIsEditing={null}
                          showEdit={false}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={4}>
                    <Grid container spacing={0}>
                      <Grid item xs={10}>
                        <IppDisplayChip
                          label={t(
                            "objPlt:objects.group.fields.proximityindicator"
                          )}
                          value={oldGroup.ProximityIndicator}
                          isEditing={false}
                          setIsEditing={null}
                          showEdit={false}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <IconButton
                          onClick={() => {
                            oldGroup.ProximityIndicator
                              ? formik.setFieldValue(
                                  "ProximityIndicator",
                                  oldGroup.ProximityIndicator
                                )
                              : formik.setFieldValue(
                                  "ProximityIndicator",
                                  null
                                );
                          }}
                        >
                          <ArrowRight />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={4}>
                    <IppMultiTextField
                      id="ProximityIndicator"
                      label={t(
                        "objPlt:objects.group.fields.proximityindicator"
                      )}
                      required={false}
                      options={proximityIndicators}
                      noOptionsText={t("strGen:components.nooptionstext")}
                      value={formik.values.ProximityIndicator}
                      setValue={(newValue: any) =>
                        formik.setFieldValue(
                          "ProximityIndicator",
                          newValue.toString()
                        )
                      }
                      touchedFunction={formik.touched.ProximityIndicator}
                      errorFunction={formik.errors.ProximityIndicator}
                      isEditing={true}
                      setIsEditing={null}
                      multiple={true}
                      autoPopulate={false}
                      freeSolo={true}
                      toolTip={t("strGen:tooltips.proximityindicator")}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Grid container spacing={0}>
                      <Grid item xs={2}>
                        <IconButton
                          onClick={() => {
                            dupeGroup.ProximityIndicator
                              ? formik.setFieldValue(
                                  "ProximityIndicator",
                                  dupeGroup.ProximityIndicator
                                )
                              : formik.setFieldValue(
                                  "ProximityIndicator",
                                  null
                                );
                          }}
                        >
                          <ArrowLeft />
                        </IconButton>
                      </Grid>
                      <Grid item xs={10}>
                        <IppDisplayChip
                          label={t(
                            "objPlt:objects.group.fields.proximityindicator"
                          )}
                          value={dupeGroup.ProximityIndicator}
                          isEditing={false}
                          setIsEditing={null}
                          showEdit={false}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              )}
              {ft_eng_lvlOfEngagement && (
                <>
                  <Grid item xs={4}>
                    <Grid container spacing={0}>
                      <Grid item xs={10}>
                        <IppRating
                          id="LevelOfInfluence"
                          label={t(
                            "objPlt:objects.group.fields.levelofinfluence"
                          )}
                          value={oldGroup.LevelOfInfluence}
                          onChangeFunction={null}
                          isEditing={false}
                          setIsEditing={null}
                          showEditButton={false}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <IconButton
                          onClick={() =>
                            formik.setFieldValue(
                              "LevelOfInfluence",
                              oldGroup.LevelOfInfluence
                            )
                          }
                        >
                          <ArrowRight />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={4}>
                    <IppRating
                      id="LevelOfInfluence"
                      label={t("objPlt:objects.group.fields.levelofinfluence")}
                      value={formik.values.LevelOfInfluence}
                      onChangeFunction={formik.handleChange}
                      isEditing={true}
                      setIsEditing={null}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Grid container spacing={0}>
                      <Grid item xs={2}>
                        <IconButton
                          onClick={() =>
                            formik.setFieldValue(
                              "LevelOfInfluence",
                              dupeGroup.LevelOfInfluence
                            )
                          }
                        >
                          <ArrowLeft />
                        </IconButton>
                      </Grid>
                      <Grid item xs={10}>
                        <IppRating
                          id="LevelOfInterest"
                          label={t(
                            "objPlt:objects.group.fields.levelofinfluence"
                          )}
                          value={dupeGroup.LevelOfInfluence}
                          onChangeFunction={null}
                          isEditing={false}
                          setIsEditing={null}
                          showEditButton={false}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={4}>
                    <Grid container spacing={0}>
                      <Grid item xs={10}>
                        <IppRating
                          id="LevelOfInterest"
                          label={t(
                            "objPlt:objects.group.fields.levelofinterest"
                          )}
                          value={oldGroup.LevelOfInterest}
                          onChangeFunction={null}
                          isEditing={false}
                          setIsEditing={null}
                          showEditButton={false}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <IconButton
                          onClick={() =>
                            formik.setFieldValue(
                              "LevelOfInterest",
                              oldGroup.LevelOfInterest
                            )
                          }
                        >
                          <ArrowRight />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={4}>
                    <IppRating
                      id="LevelOfInterest"
                      label={t("objPlt:objects.group.fields.levelofinterest")}
                      value={formik.values.LevelOfInterest}
                      onChangeFunction={formik.handleChange}
                      isEditing={true}
                      setIsEditing={null}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Grid container spacing={0}>
                      <Grid item xs={2}>
                        <IconButton
                          onClick={() =>
                            formik.setFieldValue(
                              "LevelOfInterest",
                              dupeGroup.LevelOfInterest
                            )
                          }
                        >
                          <ArrowLeft />
                        </IconButton>
                      </Grid>
                      <Grid item xs={10}>
                        <IppRating
                          id="LevelOfInterest"
                          label={t(
                            "objPlt:objects.group.fields.levelofinterest"
                          )}
                          value={dupeGroup.LevelOfInterest}
                          onChangeFunction={null}
                          isEditing={false}
                          setIsEditing={null}
                          showEditButton={false}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              )}
              {/* end of grid block */}
              <Grid item xs={12} container justifyContent="flex-end">
                <IppSubmitButton buttonText={t("strGen:buttons.save")} />
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </form>
    );
  return (
    <Root>
      <Box display="flex" justifyContent="center">
        <Grid container className={classes.editForm} spacing={1}>
          <Grid item xs={12}>
            <Paper elevation={6} className={classes.boxSpace}>
              <Box padding={1} sx={{ borderRadius: 1 }}>
                <Typography variant="h5" gutterBottom>
                  {t("strGen:pages.merge.mergefull", {
                    fieldname: t("objPlt:objects.group.name", {
                      count: 2,
                    }),
                  })}
                </Typography>
                <Typography variant="body1">
                  {t("strGen:pages.merge.info", {
                    fieldname: t("objPlt:objects.group.name", {
                      count: 2,
                    }),
                    fieldname2: t("objPlt:objects.group.name"),
                  })}
                </Typography>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            {formView}
          </Grid>
        </Grid>
      </Box>
    </Root>
  );
};
