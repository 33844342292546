import axiosClient from "./axiosClient";
import i18next from "../i18n/config";

export async function getCustomLabels(
  accessToken: String,
  lang: string
): Promise<any> {
  const url = `/customlabels/${lang}`;

  try {
    const { data } = await axiosClient.get<any>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    const labelOverridesPlt = data.customLabels["objPlt"];
    const labelOverridesBen = data.customLabels["objBen"];
    const labelOverridesStk = data.customLabels["objStk"];
    const labelOverridesCom = data.customLabels["objCom"];

    i18next.addResources(lang, "objPlt", labelOverridesPlt);
    i18next.addResources(lang, "objBen", labelOverridesBen);
    i18next.addResources(lang, "objStk", labelOverridesStk);
    i18next.addResources(lang, "objCom", labelOverridesCom);

    //CHANGED TO DIFFERENT FUNCTION TO AVOID NEEDING TO CONVERT TO JSON TREE
    // let labelOverrides = {
    //   objects: {
    //     project: {
    //       fields: {
    //         provincestate: "Province",
    //       },
    //     },
    //     projectcomponent: {
    //       name: "Phase",
    //     },
    //   },
    // };
    //i18next.addResourceBundle("en", "objPlt", labelOverrides, true, true);

    return true;
  } catch (err: any) {
    throw err;
  }
}
