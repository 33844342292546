import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { RootState } from "app/rootReducer";
import { fetchEmployees, fetchEmployeesByCompany } from "./EmployeeSlice";
import { IppInventory } from "components/Inventory/IppInventory";
import ErrorPage from "components/ErrorPage";
import LoadingIndicator from "components/LoadingIndicator";
import { ConvertDateOffset } from "../../../utils/DateFunctions";

interface employeeInvProps {
  companyID?: number;
}

export const EmployeeInventoryPage = (props: employeeInvProps) => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();

  const compID = props.companyID;

  const { dm_Disability, dm_LGBTQ, dm_Local, dm_VisibleMinority } = useSelector(
    (state: RootState) => state.client
  );

  const DMToggle = dm_Disability || dm_LGBTQ || dm_Local || dm_VisibleMinority;

  const {
    employeeList,
    isLoading,
    error: employeesError,
    employeesById,
  } = useSelector((state: RootState) => state.employees);

  const employees = employeeList.map((id) => employeesById[id]);

  const data = employees.map((p) => {
    let n = Object.assign({}, p) as any;

    if (p.LastActive !== null) {
      n.LastActive = ConvertDateOffset(p.LastActive);
    } else {
      n.LastActive = null;
    }

    const diversityMetrics = [];

    if (p.Local && dm_Local) {
      diversityMetrics.push("Local");
    }
    if (p.Disability && dm_Disability) {
      diversityMetrics.push("Person(s) with Disability");
    }
    if (p.LGBTQ && dm_LGBTQ) {
      diversityMetrics.push("LGBTQ+");
    }
    if (p.VisibleMinority && dm_VisibleMinority) {
      diversityMetrics.push("Visible Minority");
    }

    n.Diversity = diversityMetrics.join(", ");

    return n;
  });

  const empCols = [
    { field: "CompanyEmployeeID", title: "Employee ID", columnWidth: 130 },
    { field: "CompanyName", title: "Employer" },
    { field: "TownCity", title: "Town/City" },
    { field: "ProvinceState", title: "Province/State" },
    {
      field: "IndigenousGroupName",
      title: "Indigenous Group",
    },
    ...(DMToggle
      ? [
          {
            field: "Diversity",
            title: "Diversity",
            commaDelimited: true,
          },
        ]
      : []),
    {
      field: "LastActive",
      title: "Last Active",
      filter: "date",
      format: "{0:d}",
      columnWidth: 150,
    },
  ];

  useEffect(() => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE || "",
          },
        });
        if (compID) {
          dispatch(fetchEmployeesByCompany(accessToken, compID));
        } else {
          dispatch(fetchEmployees(accessToken));
        }
      } catch (e) {
        console.error(e);
      }
    })();
  }, [compID, dispatch, getAccessTokenSilently]);

  if (employeesError) {
    return <ErrorPage errMsg={employeesError} />;
  }

  let employeeInvView = isLoading ? (
    <LoadingIndicator />
  ) : (
    <div>
      <IppInventory
        title="Employees"
        tableData={data}
        idField="EmployeeID"
        columns={empCols}
        isFiltered={true}
        showDetail={true}
        buttonProps={{
          showAdd: true,
          showTemplate: true,
          showUpload: true,
          templateType: "employee",
        }}
        searchByColumn={"CompanyEmployeeID"}
        searchByPlaceholder={"Search for Employee ID"}
      />
    </div>
  );

  return <div id="employee-inventory-page">{employeeInvView}</div>;
};
